import { Box, Button } from "@mui/material";
import React, { useState } from "react"
import ListOfHelpers from "./ListOfHelpers";
import HelpRequesterMap from "./HelpRequesterMap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { menuActive  } from '../../store/menuSlice';
import { useEffect } from "react";

export const buttonPrimary = {
    backgroundColor: '#00D0FF17',
    border: '1px solid #1F2BA6',
    padding: '0.5rem 1rem',
    width: 'auto',
    borderRadius: '8px',
    color: '#1F2BA6',
    boxShadow: 'none',
    width: '100%',
    '&:hover': {
        backgroundColor: '#00D0FF17',
        color: '#1F2BA6',
        boxShadow: 'none'
    },
}

export const buttonInPrimary = {
    backgroundColor: '#FBFBFB',
    border: 'none',
    padding: '0.5rem 1rem',
    width: 'auto',
    color: '#747676',
    width: '100%',
    boxShadow: 'none',
    '&:hover': {
        backgroundColor: '#FBFBFB',
        color: '#747676',
        boxShadow: 'none'
    },
}

export default function HelperInformation() {
    const location = useLocation()
    const navigate = useNavigate()
    const [value, setValue] = useState(0);
    const dispatch = useDispatch()

    if (!location.state) {
        location.state = { value: 0 }
    }

    useEffect(() => {
        dispatch(menuActive('รับแจ้งเหตุฉุกเฉิน'))
    },[])

    return (
        <>
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                fontSize: 18,
                gridGap:'1rem',
                '@media (max-width: 1023px)': {
                    fontSize: 14,
                },
                '@media (max-width: 800px)': {
                    gridTemplateColumns: '1fr',
                    fontSize: 12,
                }
            }}>
                <Button
                    onClick={() => {
                        setValue(0)
                        navigate("/auth/helperInformation", { state: { value: 0 } })
                    }}
                    sx={location.state.value === 0 ? buttonPrimary : buttonInPrimary}
                >แผนที่</Button>
                <Button
                    onClick={() => {
                        setValue(1)
                        navigate("/auth/helperInformation", { state: { value: 1 } })
                    }}
                    sx={location.state.value === 1 ? buttonPrimary : buttonInPrimary}
                >รายชื่อผู้ขอความช่วยเหลือ</Button>
            </Box>

            {location.state.value === 0 ?
                <HelpRequesterMap />
                : null
            }
            {location.state.value === 1 ?
                <ListOfHelpers />
                : null
            }
        </>
    )
}