export const environment = {
    // production
    // production: true,
    // production_domain: 'https://api-thaicom.numeddatacenter.com'

    // Uat
    production: false,
    development_domain: 'https://api-thaicom-uat.numeddatacenter.com' 
}

