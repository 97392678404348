import React from 'react'
import { Grid, TextField, Select, MenuItem, Typography, Button, Box,FormControl,Radio,Link } from "@mui/material"
import styles from './Register.module.css'
import ModalSuccess from '../components/ModalController/ModalSuccess';
import { useState } from 'react';
import ModalConditionRegister from '../components/ModalController/ModalConditionRegister';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import * as api from '../services/public_api'
import * as apiOnesId from '../services/ApiOnesId'
import { useEffect } from 'react';
import LoaderAnimation from '../components/LoaderAnimation';
import { notifyError, notifyInfo,notifyWarning } from "../components/NotifyToast";
import { useLocation } from 'react-router';

function Register() {
    const routeState = useLocation()
    const [loading, setLoading] = useState(true)
    const [isModalSuccess,setIsModalSuccess] = useState(false)
    const [isModalConditionRegister,setIsModalconditionRegister] = useState(false)
    const [messageConditionRegister,setMessageConditionRegister] = useState({
        tite:'',
        detail:''
    })
    const [titleName,setTitleName] = useState([])
    const [hospital,setHospital] = useState([])
    const [roleGroup,setRoleGroup] = useState([])
    const [department,setDepartment] = useState([])

    const [register,setRegister] = useState({
        title_name: '',
        fname: '',
        lname:'',
        email:'',
        cid:'',
        password:'',
        confirm_password:'',
        group_role:'',
        hospital_code:'',
        department_code:'',
        position:'',
        phone:'',
        confirm_register: true
    })

    if(!routeState.state){
        routeState.state = {register:'normal'}
    }

    useEffect(() => {
        findTitleName()
        findHospital()
        findRoleGroup()
        findDepartment()

        if(routeState.state.register === 'onesid'){
            console.log(routeState.state.data['cid'])
            let data = routeState.state.data
            setRegister({
                title_name: data['titleName'],
                fname: data['fname'],
                lname: data['lname'],
                email: data['email'],
                cid: data['cid'],
                password:'-',
                confirm_password:'-',
            })
        }
    },[])

    const findTitleName = async() => {
        setLoading(true)
        let res = await api.findTitleName()
        setLoading(false)
        if(res){
            // console.log("res ====> ",res)
            if(res['data']['code'] === 200){
                setTitleName(res['data']['data'])
            }else{
                notifyInfo(res['data']['message'])
            }
        }else{
            notifyError('ไม่ตอบสนอง')
        }
    }

    const findHospital = async() => {
        setLoading(true)
        let res = await api.findHospital()
        setLoading(false)
        if(res){
            // console.log("res ====> ",res)
            if(res['data']['code'] === 200){
                setHospital(res['data']['data'])
            }else{
                notifyInfo(res['data']['message'])
            }
        }else{
            notifyError('ไม่ตอบสนอง')
        }
    }

    const findRoleGroup = async() => {
        setLoading(true)
        let res = await api.findRoleGroup()
        setLoading(false)
        let data = []
        if(res){
            // console.log("res ====> ",res)
            if(res['data']['code'] === 200){
                res['data']['data'].map((i) => {
                    if(i['GroupCode'] !== 'SUPERADMIN'){
                        data.push(i)   
                    }
                })
                // console.log(data)
                setRoleGroup(data)
            }else{
                notifyInfo(res['data']['message'])
            }
        }else{
            notifyError('ไม่ตอบสนอง')
        }
    }

    const findDepartment = async() => {
        setLoading(true)
        let res = await api.findDepartment()
        setLoading(false)
        if(res){
            // console.log("res ====> ",res)
            if(res['data']['code'] === 200){
                setDepartment(res['data']['data'])
            }else{
                notifyInfo(res['data']['message'])
            }
        }else{
            notifyError('ไม่ตอบสนอง')
        }
    }

    const [showPassword,setShowPassword] = useState({
        password: false,
        confirm_password: false,
    })

    const handlePassword = (type_password) => {
        switch(type_password){
                case 'password' : setShowPassword({...showPassword,password: !showPassword['password']}) 
            break
                case 'confirm_password' : setShowPassword({...showPassword,confirm_password: !showPassword['confirm_password']}) 
            break
        }
    }

    const handleModleSuccess = () => {
        setIsModalSuccess(!isModalSuccess)
    }

    const handleModalConditionRegister = (type_condition) => {
        console.log('Tyoe condition ====> ', type_condition)
        setIsModalconditionRegister(!isModalConditionRegister)
        switch(type_condition){
            case "condition_use" :
              setMessageConditionRegister({
                title:'เงื่อนไขการใช้งาน',
              })
              break 
            case "condition_policy" : 
              setMessageConditionRegister({
                title:'นโยบายความเป็นส่วนตัว'
              })
              break
              default:break
          }
    }

    const handleSubmitForm = async() => {
        if(!register['title_name']){
            return notifyWarning('กรุณากรอก คำนำหน้าชื่อ')
        }
        if(!register['fname']){
            return notifyWarning('กรุณากรอก ชื่อ')
        }
        if(!register['lname']){
            return notifyWarning('กรุณากรอก นามสกุล')
        }
        if(!register['email']){
            return notifyWarning('กรุณากรอก email')
        }
        if(!register['cid']){
            return notifyWarning('กรุณากรอก เลขบัตรประจำตัวประชาชน')
        }
        if(register['cid'].toString().length !== 13){
            return notifyWarning('กรุณากรอก เลขบัตรประจำตัวประชาชนให้ครบ')
        }
        if(!register['password']){
            return notifyWarning('กรุณากรอก รหัสผ่าน')
        }
        if(!register['confirm_password']){
            return notifyWarning('กรุณากรอก ยืนยันรหัสผ่าน')
        }
        if(!register['group_role']){
            return notifyWarning('กรุณากรอก ประเภทผู้ใช้งาน')
        }
        if(!register['hospital_code']){
            return notifyWarning('กรุณากรอก โรงพยาบาล')
        }
        // if(!register['department_code']){
        //     return notifyWarning('กรุณากรอก แผนก')
        // }
        if(!register['position']){
            return notifyWarning('กรุณากรอก ตำแหน่ง')
        }
        if(!register['phone']){
            return notifyWarning('กรุณากรอก เบอร์โทรศัพท์')
        }
        if(register['phone'].length !== 10){
            return notifyWarning('กรุณากรอก เบอร์โทรศัพท์ให้ครบ')
        }
        if(!register['confirm_register']){
            return notifyWarning('กรุณากรอก กดยอมรับข้อตกลง')
        }
        if(register['password'] !== register['confirm_password']){
            return notifyWarning('รหัสผ่านไม่ตรงกัน')
        }

        // console.table("title_name => ",register['title_name'])
        // console.table("fname => ",register['fname'])
        // console.table("lname => ",register['lname'])
        // console.table("email => ",register['email'])
        // console.table("cid => ",register['cid'])
        // console.table("password => ",register['password'])
        // console.table("confirm_password => ",register['confirm_password'])
        // console.table("group_role => ",register['group_role'])
        // console.table("hospital_code => ",register['hospital_code'])
        // console.table("department_code => ",register['department_code'])
        // console.table("position => ",register['position'])
        // console.table("phone => ",register['phone'])
        // console.table("confirm_register => ",register['confirm_register'])

        let res = ''

        setLoading(true)
        if(routeState.state.register === 'onesid'){
            res = await apiOnesId.registerOnesId(
                register['email'],
                register['password'],
                register['cid'],
                register['title_name'],
                register['fname'],
                register['lname'],
                register['confirm_register'],
                register['group_role'],
                register['hospital_code'],
                register['phone'],
                register['department_code'],
                register['position']
                )
        }else{
            res = await api.register(
                register['email'],
                register['password'],
                register['cid'],
                register['title_name'],
                register['fname'],register['lname'],
                register['confirm_register'],
                register['group_role'],
                register['hospital_code'],
                register['phone'],
                register['department_code'],
                register['position']
                )
        }
        
        setLoading(false)
        if(res){
            //  console.log("res ====> ",res)
            if(res['data']['code'] === 200){
                setIsModalSuccess(true)

            }else{
                notifyInfo(res['data']['message'])
            }
        }else{
            notifyError('ไม่ตอบสนอง')
        }
    }

    const handleOnChange = (e) => {
        const {name,value} = e.target
        if(name === 'cid'){
            const maxLength = 13;
            const numericRegex = /^[0-9]*$/;
            if (value.length <= maxLength && numericRegex.test(value)) {
                setRegister((prevRegister) => ({ ...prevRegister, [name]: value }));
            }
            return
        }
        if(name === 'phone'){
            const maxLength = 10;
            const numericRegex = /^[0-9]*$/;
            if (value.length <= maxLength && numericRegex.test(value)) {
                setRegister((prevRegister) => ({ ...prevRegister, [name]: value }));
            }
            return
        }

        setRegister({...register,[name]:value})
        
    }

    const boxPassword = {
        position:'relative'
    }
    const boxIcon = {
        position:'absolute',
        top:'1rem',
        right:'1rem',
        color:'#747676'
    }

  return (
    <div className={styles.contentRegister}>
        <LoaderAnimation loading={loading} />
        {
            isModalSuccess? (
                <ModalSuccess
                    title =  {'ลงทะเบียนสำเร็จ'}
                    detail = { 
                    <>
                        <span>กรุณาติดต่อผู้ดูแลระบบของสถานพยาบาลของท่าน</span>
                            <br />
                        <span>เพื่อเปิดใช้งานระบบ</span>
                    </>
                    }
                    closeModal={() => handleModleSuccess()}
                    goPage={'/'} 
                />
            ) : ''
        }
        {
            isModalConditionRegister? (
                <ModalConditionRegister
                    title={messageConditionRegister['title']}
                    closeModal={() => handleModalConditionRegister()}
                />
            ) : ''
        }
        
        <div className={styles.boxRegister}>
            <Typography component="h4" variant="h4" color='primary' sx={{textAlign:'center',margin:'2rem 0',fontWeight:'bold'}}>ลงทะเบียน</Typography>
            
            <Grid container spacing={2}> 
                <Grid item xs={12} md={4} sm={12} lg={4} xl={3} >
                    <Typography component="p" variant="p" color='primary' sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>คำนำหน้าชื่อ</Typography>
                    <FormControl fullWidth>
                            <Select
                            name='title_name'
                            value={register['title_name'] || ' '}
                            onChange={handleOnChange}
                            fullWidth
                            disabled={routeState.state.register === 'onesid' ? true : false}
                            >
                            <MenuItem  value=" ">เลือก</MenuItem>
                            {
                                titleName.map((item,index) => (
                                    <MenuItem  key={`T${item['TitleNameID']}`} value={item['TitleName']}>{item['TitleName']}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item  xs={12} md={4} sm={6} xl={4.5}>
                    <Typography component="p" variant="p" color='primary' sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>ชื่อ</Typography>
                    <TextField fullWidth placeholder="ชื่อ" variant="outlined" 
                        type='text'
                        name='fname' 
                        value={register['fname']} 
                        disabled={routeState.state.register === 'onesid' ? true : false}
                        onChange={handleOnChange}/>
                </Grid>
                <Grid item  xs={12} md={4} sm={6} xl={4.5}>
                    <Typography component="p" variant="p" color='primary' sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>นามสกุล</Typography>
                    <TextField fullWidth placeholder="นามสกุล" variant="outlined" 
                        type='text'
                        name='lname' 
                        value={register['lname']} 
                        disabled={routeState.state.register === 'onesid' ? true : false}
                        onChange={handleOnChange} />
                </Grid>
                <Grid item  xs={12}>
                    <Typography component="p" variant="p" color='primary' sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>อีเมล</Typography>
                    <TextField fullWidth placeholder="อีเมล" variant="outlined" 
                        type='email'
                        name='email' 
                        value={register['email']} 
                        disabled={routeState.state.register === 'onesid' ? true : false}
                        onChange={handleOnChange} />
                </Grid>
                <Grid item  xs={12}>
                    <Typography component="p" variant="p" color='primary' sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>เลขบัตรประจำตัวประชาชน</Typography>
                    <TextField fullWidth placeholder="เลขบัตรประจำตัวประชาชน" variant="outlined" 
                        type='text'
                        name='cid' 
                        value={register['cid']} 
                        maxLength={13}
                        disabled={routeState.state.register === 'onesid' ? true : false}
                        onChange={handleOnChange} />
                </Grid>
                <Grid item  xs={12} sx={{display: routeState.state.register === 'onesid' ? 'none' : 'block'}}>
                    <Typography component="p" variant="p" color='primary' sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>รหัสผ่าน</Typography>
                    <Box sx={boxPassword}>
                        <TextField fullWidth placeholder="รหัสผ่าน" variant="outlined" type={showPassword['password'] ? 'text' : 'password'} 
                            name='password' 
                            value={register['password']} 
                            disabled={routeState.state.register === 'onesid' ? true : false}
                            onChange={handleOnChange} />
                        <Box sx={boxIcon} onClick={() => handlePassword('password')}>
                            {
                                showPassword['password'] ?   (<RemoveRedEyeOutlinedIcon/>) : (<VisibilityOffOutlinedIcon/>) 
                            } 
                        </Box>
                    </Box>
                </Grid>
                <Grid item  xs={12} sx={{display: routeState.state.register === 'onesid' ? 'none' : 'block'}}>
                    <Typography component="p" variant="p" color='primary' sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>ยืนยันรหัสผ่าน</Typography>
                    <Box sx={boxPassword}>
                        <TextField fullWidth placeholder="ยืนยันรหัสผ่าน" variant="outlined" type={showPassword['confirm_password'] ? 'text' : 'password'}
                            name='confirm_password' 
                            value={register['confirm_password']} 
                            disabled={routeState.state.register === 'onesid' ? true : false}
                            onChange={handleOnChange} />
                            
                        <Box sx={boxIcon} onClick={() => handlePassword('confirm_password')}>
                            {
                                showPassword['confirm_password'] ?   (<RemoveRedEyeOutlinedIcon/>) : (<VisibilityOffOutlinedIcon/>) 
                            } 
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="p" variant="p" color='primary' sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>ประเภทผู้ใช้งาน</Typography>
                    <FormControl fullWidth>
                            <Select
                             name='group_role'
                             value={register['group_role'] || ' '}
                             onChange={handleOnChange}
                            fullWidth
                        >
                            <MenuItem  value=" ">เลือกประเภทผู้ใช้งาน</MenuItem>
                        {
                            roleGroup.map((item) => (
                                <MenuItem  key={item['GroupCode']} value={item['GroupCode']}>{item['GroupName']}</MenuItem>
                            ))
                        }   
                        </Select> 
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="p" variant="p" color='primary' sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>โรงพยาบาล</Typography>
                    <FormControl fullWidth>
                            <Select
                            name='hospital_code'
                            value={register['hospital_code'] || ' '}
                            onChange={handleOnChange}                           
                            fullWidth
                        >
                                <MenuItem  value=" ">เลือกโรงพยาบาล</MenuItem>
                        {
                            hospital.map((item) => (
                                <MenuItem  key={item['HospitalCode']} value={item['HospitalCode']}>{item['HospitalName']}</MenuItem>
                            ))
                        }   
                        </Select> 
                    </FormControl>
                </Grid>
                <Grid item  xs={12}>
                    <Typography component="p" variant="p" color='primary' sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>ตำแหน่ง</Typography>
                    <TextField fullWidth placeholder="ตำแหน่ง" variant="outlined" 
                        type='text'
                        name='position' 
                        value={register['position']} 
                        onChange={handleOnChange} />
                </Grid>
                {/* <Grid item xs={12}>
                    <Typography component="p" variant="p" color='primary' sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>แผนก</Typography>
                    <FormControl fullWidth>
                            <Select
                            name='department_code'
                            value={register['department_code'] || ' '}         
                            onChange={handleOnChange}                   
                            fullWidth
                        >
                            <MenuItem  value=" ">เลือกแผนก</MenuItem>
                        {
                            department.map((item) => (
                                <MenuItem  key={item['DepartmentTypeCode']} value={item['DepartmentTypeCode']}>{item['DepartmentTypeName']}</MenuItem>
                            ))
                        }   
                        </Select> 
                    </FormControl>
                </Grid> */}
                <Grid item  xs={12}>
                    <Typography component="p" variant="p" color='primary' sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>เบอร์โทรศัพท์</Typography>
                    <TextField fullWidth placeholder="เบอร์โทรศัพท์" variant="outlined" 
                            type='text'
                            name='phone'
                            value={register['phone']}         
                            onChange={handleOnChange}            
                    />
                </Grid>
                {/* <Grid item  xs={12}>
                    <Box sx={{display:'flex',justifyContent:'start',alignItems:'center'}}>
                        <Radio
                            value={register['confirm_register']}
                            onClick={() => setRegister({...register,['confirm_register']:true})}    
                        />
                        <p className={styles.titleCondition}>ยอมรับข้อตกลง <span className={styles.condition} onClick={() => handleModalConditionRegister('condition_use')}>เงื่อนไขการใช้งาน</span> และ <span className={styles.condition} onClick={() => handleModalConditionRegister('condition_policy')}>นโยบายความเป็นส่วนตัว</span></p>
                    </Box>
                </Grid> */}
            </Grid>

            <Box sx={{display:'flex',justifyContent:'center'}}>
                <Button
                    onClick={() => handleSubmitForm()}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 3, borderRadius: '8px', width:'70%' , padding:'0.5rem 1rem'}}
                    >
                    {'ลงทะเบียน'}
                </Button>
            </Box>

            <Box sx={{display:'flex',justifyContent:'center'}}>
                <Link href="/" sx={{textDecoration:'none'}} className={styles.goBack}>{'< กลับสู่หน้าเข้าสู่ระบบ'}</Link>
            </Box>
        </div>
    </div>
  )
}

export default Register