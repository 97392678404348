import { InputAdornment, Box, FormControl, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Stack, Pagination, Select, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as api from '../../services/ApiDrugDelivery'

import ModalCheckAddress from "../../components/ModalController/ModalCheckAddress";
import LoaderAnimation from '../../components/LoaderAnimation'
import ModalSuccess from '../../components/ModalController/ModalSuccess'
import ModalContactConference from "../../components/ModalController/ModalContactConference";
import ModalCalendar from "../../components/ModalController/ModalCalendar";

import moment from "moment";
import dayjs from 'dayjs';
import buddhistEra from "dayjs/plugin/buddhistEra";
import 'dayjs/locale/th';


import IconButton from '@mui/material/IconButton';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { notifyError, notifyInfo } from "../../components/NotifyToast";

export default function SentDrug({ dataSystemSent, numPage, setNumPage, maxPage, page, setPage, convertDate, searchData, namePatient, pid }) {

    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [openModalSuccess, setOpenModalSuccess] = useState(false)

    const [openModalShare, setOpenModalShare] = useState(false)

    const [dataPid, setDataPid] = useState('')
    const [dataDocNumber, setDataDocNumber] = useState('')

    const [showList, setShowList] = useState([])

    const [PID, setPID] = useState('')

    dayjs.extend(buddhistEra);
    dayjs.locale('th');

    // console.log("data ====> ",searchData)

    useEffect(() => {
        setPage(1)
    }, [numPage])

    useEffect(() => {
        let start = page * numPage - numPage
        setShowList(searchData.slice(start, start + numPage))
    }, [page, dataSystemSent, namePatient, pid])

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const confirmDrug = async (DocN) => {
        setLoading(true)
        let res = await api.confirmDrug(DocN)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setOpenModalSuccess(true)
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const documentViewer = async (Doc) => {
        setLoading(true)
        let res = await api.documentViewer(Doc)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                let url = res['data']['data']['url']
                window.open(url, '_system')
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const handleCheckAddress = (DocNumber, pid, name) => {
        setDataDocNumber(DocNumber)
        setDataPid(pid)
        setOpenModal(true)
    }

    const handleStatus = (DocN) => {
        confirmDrug(DocN)
    }

    const documents = (Doc) => {
        documentViewer(Doc)
    }

    const ShareTelepharmacy = (pid) => {
        setPID(pid)
        setOpenModalShare(true)
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            <Box sx={{
                backgroundColor: '#FFFFFF',
                border: '1px solid #E1E3E9',
                borderRadius: 2,
                marginTop: 2,
                fontSize: 18,
                '@media (max-width: 1023px)': {

                },
                '@media (max-width: 800px)': {
                    fontSize: 14
                }
            }}>
                <TableContainer>
                    <Table sx={{ marginTop: 3, minWidth: '1300px' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: '#1F2BA6', textAlign: 'center' }}>#</TableCell>
                                <TableCell sx={{ color: '#1F2BA6' }}>เลขบัตรประจำตัวประชาชน</TableCell>
                                <TableCell sx={{ color: '#1F2BA6' }}>รายชื่อ</TableCell>
                                <TableCell sx={{ color: '#1F2BA6', textAlign: 'center' }}>วันที่สั่งยา</TableCell>
                                <TableCell sx={{ color: '#1F2BA6', textAlign: 'center' }}>Telepharmacy</TableCell>
                                <TableCell sx={{ color: '#1F2BA6', textAlign: 'center' }}>รายการสั่งยา</TableCell>
                                <TableCell sx={{ color: '#1F2BA6', textAlign: 'center' }}>ที่อยู่</TableCell>
                                <TableCell sx={{ color: '#1F2BA6', textAlign: 'center' }}>สถานะ</TableCell>
                            </TableRow>
                        </TableHead>
                        {showList.length === 0 ?
                            <TableBody>
                                <TableRow>
                                    <TableCell 
                                        colSpan={12}
                                        align='center'
                                    >
                                        <Typography 
                                        component={"p"}
                                        sx={{margin:'1rem 0'}}
                                        >
                                            ไม่พบข้อมูล
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            : null
                        }

                        {showList.map((i, idx) => (
                            <TableBody key={idx}>
                                <TableRow>
                                    <TableCell sx={{ color: '#747676', textAlign: 'center' }}>{1 + idx}</TableCell>
                                    <TableCell sx={{ color: '#747676' }}>{i['PID']}</TableCell>
                                    <TableCell sx={{ color: '#747676' }}>{i['Name']}</TableCell>
                                    <TableCell sx={{ color: '#747676', textAlign: 'center' }}>{convertDate(i['DoctorSaveDate'])}</TableCell>
                                    <TableCell sx={{ color: '#747676', textAlign: 'center' }}>
                                        <Button
                                            variant="contained"
                                            sx={{ borderRadius: 1, width: '100%' }}
                                            onClick={() => { ShareTelepharmacy(i['PID']) }}
                                        >แชร์ห้อง Telepharmacy</Button>
                                    </TableCell>
                                    <TableCell sx={{ color: '#747676', textAlign: 'center' }}>
                                        <Button
                                            variant="contained"
                                            sx={{ borderRadius: 1, width: '100%' }}
                                            onClick={() => { documents(i['DocNumber']) }}
                                        >เอกสารใบสั่งยา</Button>
                                    </TableCell>
                                    <TableCell sx={{ color: '#747676', textAlign: 'center' }}>
                                        <Button
                                            variant="contained"
                                            sx={{ borderRadius: 1, width: '100%' }}
                                            onClick={() => { handleCheckAddress(i['DocNumber'], i['PID']) }}
                                        >ตรวจสอบที่อยู่</Button>
                                    </TableCell>
                                    <TableCell sx={{ color: '#747676', textAlign: 'center' }}>
                                        <Button
                                            variant="outlined"
                                            onClick={() => { handleStatus(i['DocNumber']) }}
                                        >ยืนยันการจ่ายยา</Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ))}
                    </Table>
                </TableContainer>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: 2
                }}>
                    <Box sx={{ width: '100px' }}>
                        <FormControl fullWidth>
                            <Select
                                value={numPage}
                                onChange={(e) => {
                                    const {
                                        target: { value },
                                    } = e;
                                    setNumPage(value)
                                }}
                                size='small'
                                sx={{ width: '100px', fontSize: '14px' }}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Stack spacing={2}>
                        <Pagination
                            count={maxPage}
                            page={page}
                            onChange={handlePageChange}
                            shape="rounded"
                            color="primary"
                        />
                    </Stack>
                </Box>

            </Box>

            {openModal ?
                <ModalCheckAddress
                    onClose={() => { setOpenModal(false) }}
                    pid={dataPid}
                    DocNumber={dataDocNumber}
                />
                : null
            }
            {openModalSuccess ?
                <ModalSuccess
                    title={'ยืนยันการจ่ายยา'}
                    detail={'ท่านได้ยืนยันการจ่ายยาเรียบร้อยแล้ว'}
                    goPage={''}
                    closeModal={() => { setOpenModalSuccess(false) }}
                />
                : null
            }
            {openModalShare ?
                <ModalContactConference
                    onClose={() => { setOpenModalShare(false) }}
                    pid={PID}
                />
                : null
            }

        </>
    )
}