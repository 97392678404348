export const backgroundModal = {
  backgroundColor: '#1C1C1C66',
  position: 'fixed',
  width: '100%',
  minHeight: '100vh',
  height:'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: '0',
  left: '0',
  zIndex: 999
}

export const backgroundModal2 = {
  backgroundColor: '#1C1C1C66',
  position: 'fixed',
  width: '100%',
  minHeight: '100vh',
  height:'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: '0',
  left: '0',
  zIndex: 999
}


export const boxModal = {
  backgroundColor: '#fff',
  width: 'calc(350px + 10vw)',
  // minHeight:'100vh',
  height: 'auto',
  padding: { xl: '1rem', lg: '1rem', md: '1rem', sm: '1rem', xs: '1rem' },
  borderRadius: '10px',
  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  margin:'1rem 0',
  // textAlign:'justify' 

}

export const boxModalV3 = {
  backgroundColor: '#fff',
  width: 'calc(600px + 15vw)',
  height: 'auto',
  padding: { xl: '1.5rem', lg: '1.5rem', md: '1.5rem', sm: '1rem', xs: '1rem' },
  borderRadius: '10px',
  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
}

export const boxModalV2 = {
  backgroundColor: '#fff',
  width: 'calc(250px + 10vw)',
  height: 'auto',
  padding: { xl: '1rem', lg: '0.6rem', md: '0.4rem', sm: '0.1rem', xs: '0.01rem' },
  borderRadius: '10px'
}

export const boxModalV4 = {
  backgroundColor: '#fff',
  width: 'calc(400px + 15vw)',
  height: 'auto',
  padding: { xl: '2rem', lg: '2rem', md: '2rem', sm: '1rem', xs: '1rem' },
  borderRadius: '10px',
  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
}

export const buttomPrimary = {
  backgroundColor: '#1F2BA6',
  border: '1px solid #1F2BA6',
  padding: '0.5rem 1rem',
  fontSize: 'calc(15px + 0.10vw)',
  width: 'auto',
  borderRadius: 1,
  color: '#fff',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
  },
}
export const buttomSecondary = {
  backgroundColor: '#fff',
  border: '1px solid #1F2BA6',
  padding: '0.5rem 1rem',
  fontSize: 'calc(15px + 0.10vw)',
  width: 'auto',
  borderRadius: 1,
  color: '#1F2BA6',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#fff',
    color: '#1F2BA6',
    boxShadow: 'none',
  }
}

export const boxItem = {
  border: '1px solid #E1E3E9',
  borderRadius: '10px',
  padding: { xl: '2rem', lg: '2rem', md: '2rem', sm: '1rem', xs: '1rem' },
  margin: '2rem 0.5rem',
  overflow: 'auto',
  height:'calc(450px + 10vw)'
}