import { Box, Button, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from './ConsultDoctor.module.css'
import * as api from '../../services/ApiConsultDoctor'
import LoaderAnimation from '../../components/LoaderAnimation'
import tabBmi from '../../assets/img/tab_bmi.svg'
import tabBloodPrNormal from '../../assets/img/tab_blood_pressure_narmal.svg'
import tabBloodPrSick from '../../assets/img/tab_blood_pressure_sick.svg'
import tabSugarNormal from '../../assets/img/tab_blood_sugar_normal.svg'
import tabSugarNormal8 from '../../assets/img/tab_blood_sugar_normal8.svg'
import tabSugarSick2 from '../../assets/img/tab_blood_sugar_sick2.svg'
import tabSugarSick8 from '../../assets/img/tab_blood_sugar_sick8.svg'

import { notifyError, notifyInfo } from "../../components/NotifyToast";

import { Bar } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Link } from "react-router-dom";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function PointOfCare({ pid }) {
    const [value, setValue] = useState(0);

    return (
        <>
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                fontSize: 18,
                marginTop: 5,
                marginLeft: 5,
                marginRight: 5,
                '@media (max-width: 1023px)': {
                    fontSize: 14,
                },
                '@media (max-width: 800px)': {
                    gridTemplateColumns: '1fr',
                    fontSize: 12,
                }
            }}>
                <div onClick={() => { setValue(0) }} className={value === 0 ? styles.btGreen : styles.btGay} >ผลตรวจเลือด</div>
                <div onClick={() => { setValue(1) }} className={value === 1 ? styles.btGreen : styles.btGay} >Health Station</div>
                <div onClick={() => { setValue(2) }} className={value === 2 ? styles.btGreen : styles.btGay} >Vitalsign</div>
            </Box>
            {value === 0 ?
                <>
                    <BloodTestResults
                        PID={pid}
                    />
                </> : null
            }
            {value === 1 ?
                <>
                    <HealthStation
                        PID={pid}
                    />
                </> : null
            }
            {value === 2 ?
                <>
                    <Vitalsign
                        PID={pid}
                    />
                </> : null
            }
        </>
    )
}

const BloodTestResults = ({ PID }) => {

    const [loading, setLoading] = useState(false)
    const [dataBloodTest, setDataBloodTest] = useState([])

    useEffect(() => {
        findBloodTestResult()
        console.log(PID)
    }, [])

    const findBloodTestResult = async () => {
        setLoading(true)
        let res = await api.findBloodTestResult(PID)
        setLoading(false)
        if (res) {
            if (res['data']['status'] === true) {
                setDataBloodTest(res['data']['data'])
            } else {
                setDataBloodTest([])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
        }

        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            {dataBloodTest.length > 0 ?
                <Box sx={{
                    display: 'flex',
                    gridGap: 20,
                    marginTop: 3,
                    overflow: "hidden",
                    overflowX: "scroll",
                    height: 'auto',
                    marginLeft: 5,
                    marginRight: 5,
                }}>
                    {dataBloodTest.map((i, idx) => (
                        <Box sx={{
                            backgroundColor: '#00D0FF17',
                            borderRadius: 2,
                            padding: 2,
                            minWidth: '350px'
                        }} key={idx}>
                            <div className={styles.date}>{convertDate(i['TestDate'])}</div>
                            <Box sx={{
                                backgroundColor: '#FFFFFF',
                                borderRadius: 2,
                                padding: 2
                            }}>
                                <div className={styles.title}>ผลตรวจเลือด</div>
                                <Box sx={{
                                    padding: 2
                                }}>
                                    {i['TestResults'].map((item, index) => (
                                        <div key={index}>
                                            <div className={styles.key}>{item['TestName']}</div>
                                            {item['TestResults'].map((value, key) => (
                                                <div className={styles.detail} key={key} >
                                                    <div className={styles.value}>{value['ResultName'] + ' : '}</div>
                                                    <div className={styles.value2}>{value['ResultValue'] + ' ' + value['ResultUnit']}</div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
                :
                <div style={{ textAlign: 'center', margin: '20%', fontSize: 28 }}>ไม่พบข้อมูล</div>
            }
        </>
    )
}

const HealthStation = ({ PID }) => {
    const [loading, setLoading] = useState(false)
    const [valueOption, setValueOption] = useState('blood_pressure')
    const [optionSelect, setOptionSelect] = useState([
        {
            key: 'blood_pressure',
            value: 'ค่าความดันโลหิต'
        },
        {
            key: 'blood_sugar',
            value: 'น้ำตาลในเลือด'
        },
        {
            key: 'bmi',
            value: 'BMI'
        },
        {
            key: 'pulse_rate',
            value: 'ชีพจร'
        },
        {
            key: 'oxygen_saturation',
            value: 'ค่าออกซิเจนในเลือด'
        }
    ])
    const [listYear, setListYear] = useState([])
    const [listHealth, setListHealth] = useState([])
    const [listColor, setListColor] = useState([])

    useEffect(() => {
        getHealthStation()
    }, [valueOption])

    const getHealthStation = async () => {
        setLoading(true)
        let res = await api.getHealthStation(PID, valueOption)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                let dataYearList = []
                let dataHealth = []
                let dataColor = []

                if (valueOption === 'blood_pressure') {
                    res['data']['blood_pressure_list'].map((i) => {
                        dataYearList.push(i['date_text'])
                        dataHealth.push(i['diastolic'])
                        dataColor.push(i['color'])
                    })
                    setListYear(dataYearList)
                    setListHealth(dataHealth)
                    setListColor(dataColor)
                } else if (valueOption === 'blood_sugar') {
                    res['data']['blood_sugar_list'].map((i) => {
                        dataYearList.push(i['date_text'])
                        dataHealth.push(i['blood_sugar'])
                        dataColor.push(i['color'])
                    })
                    setListYear(dataYearList)
                    setListHealth(dataHealth)
                    setListColor(dataColor)
                } else if (valueOption === 'bmi') {
                    res['data']['bmi_list'].map((i) => {
                        dataYearList.push(i['date_text'])
                        dataHealth.push(i['bmi'])
                        dataColor.push(i['color'])
                    })
                    setListYear(dataYearList)
                    setListHealth(dataHealth)
                    setListColor(dataColor)
                } else if (valueOption === 'oxygen_saturation') {
                    res['data']['oxygen_saturation_list'].map((i) => {
                        dataYearList.push(i['date_text'])
                        dataHealth.push(i['oxygen_saturation'])
                    })
                    setListYear(dataYearList)
                    setListHealth(dataHealth)
                } else if (valueOption === 'pulse_rate') {
                    res['data']['pulse_rate_list'].map((i) => {
                        dataYearList.push(i['date_text'])
                        dataHealth.push(i['pulse_rate'])
                    })
                    setListYear(dataYearList)
                    setListHealth(dataHealth)
                }
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const option = {
        plugins: {
            legend: {
                display: false
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    font: {
                        size: '12%'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: '12%'
                    }
                }
            }
        }
    }

    const data = {
        labels: listYear,
        datasets: [
            {
                label: '',
                data: listHealth,
                backgroundColor: (valueOption === 'oxygen_saturation' || valueOption === 'pulse_rate') ? ['#1F2BA6'] : listColor
            }
        ]
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            <Box sx={{
                backgroundColor: '#00D0FF17',
                borderRadius: 3,
                padding: 3,
                marginTop: 3,
                marginLeft: 5,
                marginRight: 5,
                '@media (max-width: 800px)': {
                    padding: 2,
                    marginTop: 2,
                    marginLeft: 2,
                    marginRight: 2
                }
            }}>
                <Box sx={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: 3,
                    paddingBottom: 6
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        '@media (max-width: 800px)': {
                            justifyContent: 'center',
                        }
                    }}>
                        <Box sx={{
                            margin: 2,
                            width: '40%',
                            '@media (max-width: 800px)': {
                                width: '100%'
                            }
                        }}>
                            <Select
                                fullWidth
                                size="small"
                                value={valueOption}
                                onChange={(event) => {
                                    const {
                                        target: { value },
                                    } = event;
                                    setValueOption(value)
                                }}
                            >
                                {optionSelect.map((i) => (
                                    <MenuItem
                                        key={i['key']}
                                        value={i['key']}
                                    >
                                        {i['value']}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: 'auto',
                        width: '600px',
                        maxHeight: '400px',
                        '@media (max-width: 1030px)': {
                            width: '500px',
                            maxHeight: '300px'
                        },
                        '@media (max-width: 500px)': {
                            width: '300px',
                            maxHeight: '200px'
                        }
                    }}>
                        <Bar
                            options={option}
                            data={data}
                        />
                    </Box>

                    {valueOption === 'blood_pressure' ?
                        <Box sx={{
                            display: 'grid',
                            justifyContent: 'center',
                            justifyItems: 'center',
                            gridTemplateColumns: '1fr 1fr',
                            marginTop: 3,
                            '@media (max-width: 1300px)': {
                                gridTemplateColumns: '1fr',
                                gridGap: 10
                            }
                        }}>
                            <img className={styles.Img} src={tabBloodPrNormal} />
                            <img className={styles.Img} src={tabBloodPrSick} />
                        </Box>
                        : null
                    }

                    {valueOption === 'bmi' ?
                        <Box sx={{
                            display: 'grid',
                            justifyContent: 'center',
                            marginTop: 3
                        }}>
                            <img className={styles.Img} src={tabBmi} />
                        </Box>
                        : null
                    }

                    {valueOption === 'blood_sugar' ?
                        <Box sx={{
                            display: 'grid',
                            justifyContent: 'center',
                            justifyItems: 'center',
                            gridTemplateColumns: '1fr 1fr',
                            marginTop: 3,
                            '@media (max-width: 1300px)': {
                                gridTemplateColumns: '1fr',
                                gridGap: 10
                            }
                        }}>
                            <img className={styles.Img} src={tabSugarNormal} />
                            <img className={styles.Img} src={tabSugarNormal8} />
                            <img className={styles.Img} src={tabSugarSick2} />
                            <img className={styles.Img} src={tabSugarSick8} />
                        </Box>
                        : null
                    }

                </Box>
            </Box>
        </>
    )
}

const Vitalsign = ({ PID }) => {

    const [loading, setLoading] = useState(false)
    const [dataVitalsign, setDataVitalsign] = useState([])
    const [notData, setNotData] = useState('')

    useEffect(() => {
        findVitalsignTestResult()
    }, [])

    const findVitalsignTestResult = async () => {
        setLoading(true)
        let res = await api.findVitalsignTestResult(PID)
        setLoading(false)
        if (res) {
            if (res['data']['status'] === true) {
                setDataVitalsign(res['data']['data'])
                console.log(res['data']['data'])
            } else {
                setNotData(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
        }

        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    const clickECG = (url) => {
        window.open(url, '_system')
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            {notData === '' ?
                <Box sx={{
                    display: 'flex',
                    gridGap: 20,
                    marginTop: 3,
                    overflow: "hidden",
                    overflowX: "scroll",
                    marginLeft: 5,
                    marginRight: 5,
                }}>
                    {dataVitalsign.map((i, idx) => (
                        <Box key={idx} sx={{
                            backgroundColor: '#00D0FF17',
                            borderRadius: 2,
                            padding: 2,
                            minWidth: '350px'
                        }}>
                            <div className={styles.date} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{convertDate(i['Date'])}</div>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        clickECG(i['Parameter']['ECG']['URL'])
                                    }}
                                >ECG</Button>
                            </div>
                            <Box sx={{
                                backgroundColor: '#FFFFFF',
                                borderRadius: 2,
                                padding: 2,
                                marginTop: 2
                            }}>
                                <Box sx={{ padding: 2 }}>
                                    <div className={styles.key}>การตรวจวิเคราะห์ปัสสาวะ (URT)</div>
                                    {Object.entries(i['Parameter']['URT']).map(([key, val]) => (
                                        <div key={key} style={{ color: '#707070', display: 'flex', gridGap: 20 }} className={styles.value3}>
                                            <div>{key}</div>
                                            <div>{val === '' ? '-' : val}</div>
                                        </div>
                                    ))}
                                    <div className={styles.key}>การตรวจระดับออกซิเจนในเลือด (Spo2)</div>
                                    <div className={styles.value3}>{i['Parameter']['SPO2']['Spo2'] === "" ? "-" : i['Parameter']['SPO2']['Spo2']}</div>

                                    <div className={styles.key}>ค่าความดันโลหิต (NIBP)</div>
                                    <div className={styles.value3}>ค่าบน Sys {i['Parameter']['NIBP']['Sys'] === "" ? "-" : i['Parameter']['NIBP']['Sys']} mmHg </div>
                                    <div className={styles.value3}>ค่าล่าง Dia {i['Parameter']['NIBP']['Dia'] === "" ? "-" : i['Parameter']['NIBP']['Dia']} mmHg </div>
                                    <div className={styles.value3}>ค่าชีพจร Pr {i['Parameter']['NIBP']['Pr'] === "" ? "-" : i['Parameter']['NIBP']['Pr']} ครั้ง / นาที </div>

                                    <div className={styles.key}>ค่าอุณหภูมิร่างกาย (TEMP)</div>
                                    <div className={styles.value3}>{i['Parameter']['TEMP']['Temp'] === "" ? "-" : i['Parameter']['TEMP']['Temp']} องศาเซลเซียส</div>

                                    <div className={styles.key}>ระดับน้ำตาลกลูโคสในเลือด (GLU)</div>
                                    <div className={styles.value3}>GLU {i['Parameter']['GLU']['GLU'] === "" ? "-" : i['Parameter']['GLU']['GLU']}</div>

                                    <div className={styles.key}>ระดับยูริค (UA)</div>
                                    <div className={styles.value3}>UA {i['Parameter']['UA']['UA'] === "" ? "-" : i['Parameter']['UA']['UA']}</div>

                                    <div className={styles.key}>ระดับคอเลสเตอรอล (TC)</div>
                                    <div className={styles.value3}>TC {i['Parameter']['TC']['TC'] === "" ? "-" : i['Parameter']['TC']['TC']}</div>

                                    <div className={styles.key}>ระดับฮีโมโกลบิน (HB)</div>
                                    <div className={styles.value3}>HB {i['Parameter']['HB']['HB'] === "" ? "-" : i['Parameter']['HB']['HB']}</div>

                                    <div className={styles.key}>การตรวจระดับใขมันในเลือด (LIPIDS)</div>
                                    <div className={styles.value3}>TG {i['Parameter']['LIPIDS']['TG'] === "" ? "-" : i['Parameter']['LIPIDS']['TG']}</div>
                                    <div className={styles.value3}>HDL {i['Parameter']['LIPIDS']['HDL'] === "" ? "-" : i['Parameter']['LIPIDS']['HDL']}</div>
                                    <div className={styles.value3}>LDL {i['Parameter']['LIPIDS']['LDL'] === "" ? "-" : i['Parameter']['LIPIDS']['LDL']}</div>

                                    <div className={styles.key}>ค่าดัชนีมวลกาย (BMI)</div>
                                    <div className={styles.value3}>Height {i['Parameter']['BMI']['Height'] === "" ? "-" : i['Parameter']['BMI']['Height']} CM</div>
                                    <div className={styles.value3}>Weight {i['Parameter']['BMI']['Weight'] === "" ? "-" : i['Parameter']['BMI']['Weight']} KG</div>
                                    <div className={styles.value3}>BMI {i['Parameter']['BMI']['Bmi'] === "" ? "-" : i['Parameter']['BMI']['Bmi']}</div>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
                :
                <div style={{ textAlign: 'center', margin: '20%', fontSize: 28 }}>ไม่พบข้อมูล</div>
            }
        </>
    )
}