import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ProSidebarProvider } from "react-pro-sidebar";
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

import { Provider } from 'react-redux';
import store, { persistor } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';

const theme = createTheme({
  palette: {
    primary: {
      light: '#1F2BA6',
      main: '#1F2BA6',
      dark: '#1F2BA6',
      contrastText: '#fff',
    },
    secondary: {
      light: '#747676',
      main: '#747676',
      dark: '#747676',
      contrastText: '#ffffff',
    },
    in_secondary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#ffffff',
    },
    color_bg: {
      light: '#FBFBFB',
      main: '#FBFBFB',
      dark: '#FBFBFB',
      contrastText: '#FBFBFB',
    }

  },
  typography: {
    allVariants: {
      color: "#1F2BA6"
    }
  },
});

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <ProSidebarProvider>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </ProSidebarProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
