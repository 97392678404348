import axios from "axios";
import { environment } from '../config.env'

const ENDPOINT_DOMAIN = environment.production ? environment.production_domain : environment.development_domain

const domain = ENDPOINT_DOMAIN

export function findTelemedicineSent(date) { //รายการผู้ป่วยที่ต้องส่งยา
    return new Promise(resolve => {
        let url = `${domain}/dashboard-api/api/v1/microservice/telemedicine/findTelemedicine?AppointDate=${date}&order=DocNumber&sort=desc&DoctorSave=Y&DocStatus=W`
        let header = {
            'Content-Type': 'application/json',
            'Platform': 'thaicom-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findTelemedicineDone(date) { //รายการผู้ป่วยที่ส่งยาแล้ว
    return new Promise(resolve => {
        let url = `${domain}/dashboard-api/api/v1/microservice/telemedicine/findTelemedicine?AppointDate=${date}&order=DocNumber&sort=desc&DrugDeliveryStatus=Y&DocStatus=S`
        let header = {
            'Content-Type': 'application/json',
            'Platform': 'thaicom-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findAddress(PID) {
    console.log(PID)
    return new Promise(resolve => {
        let url = `${domain}/dashboard-api/api/v1/microservice/data/findAddress?PID=${PID}`
        let header = {
            'Content-Type': 'application/json',
            'Platform': 'thaicom-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function confirmAddress(DocNumber, body) { //แก้ไขที่อยู่
    return new Promise(resolve => {
        let url = `${domain}/dashboard-api/api/v1/microservice/telemedicine/confirmAddress?DocNumber=${DocNumber}`
        let header = {
            'Content-Type': 'application/json',
            'Platform': 'thaicom-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.put(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function confirmDrug(DocNumber) { //ยืนยันการจ่ายยา
    console.log('dddddddd')
    return new Promise(resolve => {
        let url = `${domain}/dashboard-api/api/v1/microservice/telemedicine/confirm?DocNumber=${DocNumber}`
        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: url,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
                'Platform': 'thaicom-dashboard',
                'Content-Type': 'application/json'
            }
        }
        axios.request(config)
            .then((response) => {
                // console.log(JSON.stringify(response.data));
                resolve(response)
            })
            .catch((error) => {
                // console.log(error);
                resolve(false)
            });
    })
}

export function documentViewer(DocNumber) { //เอกสารรายการคำปรึกษาระหว่างหน่วยบริการ
    return new Promise(resolve => {
        let url = `${domain}/dashboard-api/api/v1/microservice/document-viewer?DocCode=55478f34-f7ec-433b-b402-ce5347787b15&DocNumber=${DocNumber}`
        let header = {
            'Content-Type': 'application/json',
            'Platform': 'thaicom-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

