import axios from "axios";
import { environment } from '../config.env'

export function createConferrenceRoom() {
    return new Promise(resolve => {
        let url = `https://microservice.numeddatacenter.com/api/microservice/v1/conferrence/createConferrenceRoom`
        let body = {}
        let header = {
            'Content-Type': 'application/json',
            'platform_type': 'mod-dashboard',
            'enviroment': environment.production ? 'PROD' : 'UAT',
            'Authorization': localStorage.getItem('AccessToken')
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function shareConferrence(body) {
    return new Promise(resolve => {
        let url = `https://microservice.numeddatacenter.com/api/microservice/v1/conferrence/shareConferrence`
        let header = {
            'Content-Type': 'application/json',
            'platform_type': 'mod-dashboard',
            'enviroment': environment.production ? 'PROD' : 'UAT',
            'Authorization': localStorage.getItem('AccessToken')
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}