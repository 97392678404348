import { Box, FormControl, MenuItem, Select, TableBody, TableCell, TableContainer, Table, TableHead, TableRow, TextField, Typography, Button, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import RamaIcon from '../../../assets/icons/login-icon.svg'
import styles from './ExaminationRoomQueue.module.css'
import MoadlExaminationRoomQueue from "../../../components/ModalController/ModalExaminationRoomQueue";
import * as api from '../../../services/ApiConsultDoctor'
import { notifyError, notifyInfo, notifySuccess } from "../../../components/NotifyToast";
import LoaderAnimation from "../../../components/LoaderAnimation";
import { io } from 'socket.io-client';
import { useSelector } from "react-redux";

import { environment } from '../../../config.env'

import { useDispatch } from "react-redux";
import { logout } from './../../../store/authSlice';

const ENDPOINT_DOMAIN = environment.production ? environment.production_domain : environment.development_domain

export default function ExaminationRoomQueue() {

    const dispatch = useDispatch()

    const sound_audio = useSelector((state) => {
        // console.log(state)
        return state.sound_audio.audio
    })


    const [socket, setSocket] = useState(null)
    const [topic, setTopic] = useState({
        topic_room_1: null,
        topic_room_2: null,
        topic_room_3: null,
    })
    const [loading, setLoading] = useState(false)

    const [is_play_sound, setIs_play_sound] = useState(false)

    const [hospital, setHospital] = useState('')
    const [hospitalList, setHospitalList] = useState([])

    const [department, setDepartment] = useState('')
    const [optionDepartment, setOptionDepartment] = useState([])

    const [roomList, setRoomList] = useState([])

    const [optionRoom, setOptionRoom] = useState([])

    const [location, setLocation] = useState('')
    const [optionLocation, setOptionLocation] = useState([])

    const [openModal, setOpenModal] = useState(false)

    const [roomOn, setRoomOn] = useState(null)

    const [queueRoomCurrent, setQueueRoomCurrent] = useState({
        "room_1": {
            "current_queue": null,
            "appointment_list": [],
            "room_data": null
        },
        "room_2": {
            "current_queue": null,
            "appointment_list": [],
            "room_data": null
        },
        "room_3": {
            "current_queue": null,
            "appointment_list": [],
            "room_data": null
        }
    })

    useEffect(() => {
        console.log(queueRoomCurrent)
    }, [queueRoomCurrent])

    useEffect(() => {
        console.log(hospitalList)
    }, [hospitalList])


    useEffect(() => {
        if (roomOn) {
            let room_1_topic = null
            let room_2_topic = null

            let {
                room_name_1,
                room_name_2,
                room_name_3
            } = roomOn

            console.log(roomOn)

            console.log(room_name_1)
            console.log(room_name_2)

            if (room_name_1) {
                room_1_topic = hospital['hospital_id'] + '/' + department + '/' + location + '/' + room_name_1
            }

            if (room_name_2) {
                room_2_topic = hospital['hospital_id'] + '/' + department + '/' + location + '/' + room_name_2
            }

            setTopic(prevState => (
                {
                    ...prevState,
                    topic_room_1: room_1_topic,
                    topic_room_2: room_2_topic
                }
            ))
        }

    }, [roomOn])

    useEffect(() => {
        const socket = io(ENDPOINT_DOMAIN + '/', {
            path: "/socket/socket.io",
            autoConnect: true,
            reconnection: false,
            transports: ["websocket"]
        })

        console.log(socket)

        setSocket(socket)

        socket.on("connect_error", () => {
            console.log('on : connect_error');
            socket.connect();
        });

        socket.io.on("reconnect_attempt", () => {
            console.log('on : reconnect_attempt');
        });

        socket.io.on("reconnect", () => {
            console.log('on : reconnect');
        });

        socket.on("subscribed", () => {
            console.log('on : subscribed');

        });

        socket.on("unsubscribed", () => {
            console.log('on : unsubscribed');
        });

        return () => {
            socket.disconnect();
        };
    }, [])

    useEffect(() => {
        console.log('log topic 2 room :', topic)

        if (topic['topic_room_1']) {
            console.log('start topic_room_1')
            socket.emit("unsubscribe", topic['topic_room_1']);

            socket.emit("subscribe", topic['topic_room_1']);
            console.log('ehd topic_room_1')


        }

        if (topic['topic_room_2']) {
            console.log('start topic_room_2')
            socket.emit("unsubscribe", topic['topic_room_2']);

            socket.emit("subscribe", topic['topic_room_2']);
            console.log('ehd topic_room_2')
        }

        if (socket) {

            socket.on("call_queue", (data) => {
                console.log('on : call_queue ', data);

                let {
                    topic_room_1,
                    topic_room_2,
                    topic_room_3
                } = topic

                let current_queue = data.current_queue;

                // console.log('log condition topic_room_1', topic_room_1 == current_queue.hospital_id + '/' + current_queue.clinic_code + '/' + current_queue.location + '/' + current_queue.room_name)

                // console.log(topic_room_1)
                if (topic_room_1 == current_queue.hospital_id + '/' + current_queue.clinic_code + '/' + current_queue.location + '/' + current_queue.room_name) {
                    // current_queue_1 = current_queue;
                    // console.log('log topic room 1 call queue')

                    let isCallAppointmentList = []

                    for (let i = 0; i < data.appointment_next_queue_list.length; i++) {
                        if (!data.appointment_next_queue_list[i]['is_call']) {
                            isCallAppointmentList.push(data.appointment_next_queue_list[i])
                        }
                    }

                    setQueueRoomCurrent(prevState => ({
                        ...prevState,
                        room_1: {
                            ...prevState.room_1,
                            current_queue: current_queue,
                            appointment_list: isCallAppointmentList
                        }
                    }));

                    console.log('log state data socket update', queueRoomCurrent)

                    PlayQueue(current_queue.clinic_code + addZero(current_queue.queue), current_queue.room_name)
                }
                // console.log('log condition topic_room_2', topic_room_2 == current_queue.hospital_id + '/' + current_queue.clinic_code + '/' + current_queue.location + '/' + current_queue.room_name)
                // console.log(topic_room_2)
                if (topic_room_2 == current_queue.hospital_id + '/' + current_queue.clinic_code + '/' + current_queue.location + '/' + current_queue.room_name) {
                    // current_queue_2 = current_queue;
                    // console.log('log topic room 2 call queue')

                    let isCallAppointmentList = []

                    for (let i = 0; i < data.appointment_next_queue_list.length; i++) {
                        if (!data.appointment_next_queue_list[i]['is_call']) {
                            isCallAppointmentList.push(data.appointment_next_queue_list[i])
                        }
                    }

                    setQueueRoomCurrent(prevState => ({
                        ...prevState,
                        room_1: {
                            ...prevState.room_1,
                            current_queue: current_queue,
                            appointment_list: isCallAppointmentList
                        }
                    }));

                    PlayQueue(current_queue.clinic_code + addZero(current_queue.queue), current_queue.room_name)
                }
            });


            socket.on("call_queue_direct", (data) => {
                console.log('on : call_queue_direct ', data);

                let {
                    topic_room_1,
                    topic_room_2,
                    topic_room_3
                } = topic

                let current_queue = data.current_queue;

                console.log('call_queue_direct case 1', topic_room_1, topic_room_2, current_queue)
                if (topic_room_1 == current_queue.hospital_id + '/' + current_queue.clinic_code + '/' + current_queue.location + '/' + current_queue.room_name) {
                    console.log('call_queue_direct case 2')
                    setQueueRoomCurrent(prevState => ({
                        ...prevState,
                        room_1: {
                            ...prevState.room_1,
                            current_queue: current_queue
                        }
                    }));

                    console.log('call_queue_direct case 3')

                    PlayQueue(current_queue.clinic_code + addZero(current_queue.queue), current_queue.room_name)
                    console.log('call_queue_direct case 4')
                }
                if (topic_room_2 == current_queue.hospital_id + '/' + current_queue.clinic_code + '/' + current_queue.location + '/' + current_queue.room_name) {
                    console.log('call_queue_direct case 5')
                    setQueueRoomCurrent(prevState => ({
                        ...prevState,
                        room_2: {
                            ...prevState.room_2,
                            current_queue: current_queue
                        }
                    }));
                    console.log('call_queue_direct case 6')

                    PlayQueue(current_queue.clinic_code + addZero(current_queue.queue), current_queue.room_name)
                    console.log('call_queue_direct case 7')
                }

            });


            socket.on("update_queue", (data) => {
                console.log('on : update_queue ', data);

                let {
                    topic_room_1,
                    topic_room_2,
                    topic_room_3
                } = topic

                let current_queue = data.current_date_queue.current_queue;

                if (current_queue) {
                    if (topic_room_1 == current_queue.hospital_id + '/' + current_queue.clinic_code + '/' + current_queue.location + '/' + current_queue.room_name) {


                        let isCallAppointmentList = []

                        for (let i = 0; i < data.appointment_list.length; i++) {
                            if (!data.appointment_list[i]['is_call']) {
                                isCallAppointmentList.push(data.appointment_list[i])
                            }
                        }
                        setQueueRoomCurrent(prevState => ({
                            ...prevState,
                            room_1: {
                                ...prevState.room_1,
                                current_queue: current_queue,
                                appointment_list: isCallAppointmentList
                            }
                        }));
                    }

                    if (topic_room_2 == current_queue.hospital_id + '/' + current_queue.clinic_code + '/' + current_queue.location + '/' + current_queue.room_name) {

                        let isCallAppointmentList = []

                        for (let i = 0; i < data.appointment_list.length; i++) {
                            if (!data.appointment_list[i]['is_call']) {
                                isCallAppointmentList.push(data.appointment_list[i])
                            }
                        }

                        setQueueRoomCurrent(prevState => ({
                            ...prevState,
                            room_2: {
                                ...prevState.room_2,
                                current_queue: current_queue,
                                appointment_list: isCallAppointmentList
                            }
                        }));

                    }
                }

            })

            socket.on("update_cancel_queue", (data) => {
                console.log('on : update_cancel_queue ', data);

                let {
                    topic_room_1,
                    topic_room_2,
                    topic_room_3
                } = topic

                let current_queue = data.current_date_queue.current_queue;

                if (data.appointment_list.length > 0) {

                    if (topic_room_1 == data.appointment_list[0].hospital_id + '/' + data.appointment_list[0].clinic_code + '/' + data.appointment_list[0].location + '/' + data.appointment_list[0].room_name) {


                        let isCallAppointmentList = []

                        for (let i = 0; i < data.appointment_list.length; i++) {
                            if (!data.appointment_list[i]['is_call']) {
                                isCallAppointmentList.push(data.appointment_list[i])
                            }
                        }

                        console.log('คิวที่ยังไม่ได้เรียก', isCallAppointmentList)

                        setQueueRoomCurrent(prevState => ({
                            ...prevState,
                            room_1: {
                                ...prevState.room_1,
                                current_queue: current_queue ? current_queue : null,
                                appointment_list: isCallAppointmentList
                            }
                        }));

                    }

                    else if (topic_room_2 == data.appointment_list[0].hospital_id + '/' + data.appointment_list[0].clinic_code + '/' + data.appointment_list[0].location + '/' + data.appointment_list[0].room_name) {

                        let isCallAppointmentList = []

                        for (let i = 0; i < data.appointment_list.length; i++) {
                            if (!data.appointment_list[i]['is_call']) {
                                isCallAppointmentList.push(data.appointment_list[i])
                            }
                        }

                        console.log('คิวที่ยังไม่ได้เรียก', isCallAppointmentList)

                        setQueueRoomCurrent(prevState => ({
                            ...prevState,
                            room_1: {
                                ...prevState.room_1,
                                current_queue: current_queue ? current_queue : null,
                                appointment_list: isCallAppointmentList
                            }
                        }));
                    }
                }

            });

            socket.on("update_pause", (data) => {
                console.log('on : update_pause ', data);


                let {
                    topic_room_1,
                    topic_room_2,
                    topic_room_3
                } = topic

                if (topic_room_1 == data.topic) {

                    console.log('log topic room 1 update_pause', data)

                    // room_1_data.is_pause = data.is_pause;

                    // if (room_1_data.is_pause) {
                    //     alert('พักตรวจ')
                    // }
                    // else {

                    //     if (current_queue_1) {

                    //         $('#room_1_current_queue').text(current_queue_1.clinic_code + addZero(current_queue_1.queue))
                    //     }
                    //     else {
                    //         $('#room_1_current_queue').text('-')
                    //     }
                    // }
                }
                else if (topic_room_2 == data.topic) {

                    console.log('log topic room 2 update_pause', data)

                    // room_2_data.is_pause = data.is_pause;

                    // if (room_2_data.is_pause) {
                    //     $('#room_2_current_queue').text('พักตรวจ')
                    // }
                    // else {
                    //     if (current_queue_2) {
                    //         $('#room_2_current_queue').text(current_queue_2.clinic_code + addZero(current_queue_2.queue))
                    //     }
                    //     else {
                    //         $('#room_2_current_queue').text('-')
                    //     }
                    // }
                }

            });

            socket.on("announce_update", (data) => {
                console.log('on : announce_update ', data);

            });
        }
    }, [topic])

    useEffect(() => {
        getLocation()
        getRoom()
    }, [department])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                const resClinicRoom = await api.getClinicAndRoomDashboard()
                if (resClinicRoom && resClinicRoom['data']['code'] === 200) {
                    setRoomList(resClinicRoom['data']['room_list']) //ใช้กรอง location
                    setOptionDepartment(resClinicRoom['data']['clinic_list'])
                    setHospitalList(resClinicRoom['data']['hospital_list'])
                } else {
                    notifyError(resClinicRoom['data']['message']);
                }
            } catch (error) {
                notifyError('ไม่ตอบสนอง');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [])


    function addZero(queue) {
        console.log('CASE ADD ZERO :', queue)
        return (queue + '').length == 1 ? '000' + queue : (queue + '').length == 2 ? '00' + queue : (queue + '').length == 3 ? '0' + queue : '' + queue;
    }


    let soundList = []

    const PlayQueue = async function (queue, room_name) {
        console.log('play queue case 1')
        console.log(queue)

        console.log(sound_audio['value'] === 'ON_AUDIO')
        if (sound_audio && sound_audio['value'] === 'ON_AUDIO') { //init state redux value : 'OFF_AUDIO'

            console.log('play queue case 2')

            let list_sound_queue = []

            list_sound_queue = [
                'alert',
                'call_number_new',
                ...list_sound_queue.concat(queue.split("")),
                'at_room',
                ...list_sound_queue.concat(room_name.split('')),
                'sir'
            ]

            console.log('play queue log sound list', soundList)
            // soundList = [...soundList, ...list_sound_queue]
            soundList = soundList.concat(list_sound_queue)

            console.log('is_play_sound : ', is_play_sound)

            if (!is_play_sound) {
                LoopPlaySound()
            } else {
                soundList = []
            }
        }


    };

    const PlaySound = function (path) {
        const audioElement = new Audio(path);
        try {
            audioElement.play();
            audioElement.addEventListener('ended', LoopPlaySound);
            setIs_play_sound(true);
        } catch (error) {
            console.log(error)
            setIs_play_sound(false);
            soundList = []
        }

    };

    const LoopPlaySound = function () {
        if (soundList.length > 0) {
            const soundPath = require(`../../../assets/audio/${soundList[0]}.mp3`);
            PlaySound(soundPath);
            soundList = soundList.slice(1)
        } else {
            setIs_play_sound(false);
        }

    }

    const locationList = []
    const getLocation = () => {
        for (let i = 0; i < roomList.length; i++) {
            if (roomList[i]['clinic_code'] === department) {
                const location = roomList[i]['location'];

                if (!locationList.includes(location)) {
                    locationList.push(location);
                }
            }
        }
        setOptionLocation(locationList.map(i => {
            return {
                key: i,
                value: i
            }
        }))
    }

    const roomAll = []
    const getRoom = () => {
        for (let i = 0; i < roomList.length; i++) {
            if (roomList[i]['clinic_code'] == department) {
                roomAll.push(roomList[i])
            }
        }
        setOptionRoom(roomAll.map(i => {
            return {
                key: i['room_name']
            }
        }))
    }

    const clickOpenModal = () => {
        setOpenModal(true)
    }

    const getCurrentThreeRoomDateQueue = async (hospital, department, location, room) => {
        setOpenModal(false)
        setLoading(true)
        let res = await api.getCurrentThreeRoomDateQueue(hospital['hospital_id'], department, location, room)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {

                console.log(res['data'])
                setQueueRoomCurrent({
                    room_1: res['data']['room_1'], //object
                    room_2: res['data']['room_2'], //object
                    room_3: res['data']['room_3'], //object
                })

            } else {
                if (res['data']['code'] === 401) {
                    localStorage.clear()
                    dispatch(logout())
                } else {
                    notifyInfo(res['data']['message'])
                }
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }
    const convertGroupRoom = (room) => {
        console.log(room)
        const roomName1 = room['room_name_1'];
        const roomName2 = room['room_name_2'];
        const roomName3 = room['room_name_3'];

        let groupname = '';

        if (roomName1) {
            groupname += 'ห้อง' + ' ' + roomName1;
        }

        if (roomName2) {
            if (groupname) {
                groupname += ' และ ' + 'ห้อง' + ' ' + roomName2;
            } else {
                groupname += roomName2;
            }
        }

        if (roomName3) {
            if (groupname) {
                groupname += ' และ ' + 'ห้อง' + ' ' + roomName3;
            } else {
                groupname += roomName3;
            }
        }

        // Default ถ้าไม่มีค่าห้องเลย
        if (!groupname) {
            groupname = '-';
        }

        return groupname;
    };

    const groupCodeLine = (clinic_code, queue_code) => {
        let queue_str = String(queue_code);

        if (queue_str.length < 2) {
            queue_str = '000' + queue_str;
        } else if (queue_str.length < 3) {
            queue_str = '00' + queue_str;
        } else if (queue_str.length < 4) {
            queue_str = '0' + queue_str;
        }

        return clinic_code + queue_str;
    }

    const totalQueueThreeRoom = (item) => {
        let {
            current_queue,
            appointment_list
        } = item
        if (current_queue && appointment_list.length > 0) {
            return String(1 + appointment_list.length)
        } else if (appointment_list.length > 0) {
            return String(appointment_list.length)
        } else {
            return '-'
        }
    }

    return (
        <Box sx={{
            display: 'grid',
            gridGap: 30,
            '@media (max-width: 800px)': {
                gridGap: 20
            }
        }}>
            <LoaderAnimation loading={loading} />
            <Box sx={{
                backgroundColor: '#FFFFFF',
                border: '1px solid #E1E3E9',
                borderRadius: 3,
                padding: 4,
                display: 'grid',
                gridTemplateColumns: '2fr 2fr 2fr 1fr',
                gridGap: 30,
                '@media (max-width: 1023px)': {
                    padding: 2,
                    gridTemplateColumns: '1fr 1fr',
                },
                '@media (max-width: 800px)': {
                    padding: 2,
                    gridTemplateColumns: '1fr',
                }
            }}>
                <FormControl>
                    <Typography fontWeight={'bold'}>เลือกโรงพยาบาล</Typography>
                    {/* <TextField disabled size="small" value={hospital}></TextField> */}
                    <Select
                        size="small"
                        value={hospital || ' '}
                        renderValue={(selected) => hospital ? selected.hospital_name : ' '}
                        onChange={(event) => {
                            const {
                                target: { value },
                            } = event;
                            setHospital(value) //object
                        }}
                    >
                        <MenuItem value=" ">เลือกโรงพยาบาล</MenuItem>
                        {hospitalList?.map(i => (
                            <MenuItem
                                key={i['hospital_id']}
                                value={i} //includes object in item index
                            >{i['hospital_name']}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl>
                    <Typography fontWeight={'bold'}>เลือกรหัสแผนก</Typography>
                    <Select
                        size="small"
                        value={department || ' '}
                        disabled={hospital || hospital === ' ' ? false : true}
                        onChange={(event) => {
                            const {
                                target: { value },
                            } = event;
                            setDepartment(value)
                        }}
                    >
                        <MenuItem value=" ">เลือกรหัสแผนก</MenuItem>
                        {optionDepartment.map(i => (
                            <MenuItem
                                key={i['clinic_code']}
                                value={i['clinic_code']}
                            >{i['clinic_code'] + ' (' + i['department_name'] + ')'}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl>
                    <Typography fontWeight={'bold'}>เลือกสถานที่</Typography>
                    <Select
                        size="small"
                        value={location || ' '}
                        disabled={department || department !== ' ' ? false : true}
                        onChange={(event) => {
                            const {
                                target: { value },
                            } = event;
                            setLocation(value)
                        }}
                    >
                        <MenuItem value=" ">เลือกสถานที่</MenuItem>
                        {optionLocation.map(i => (
                            <MenuItem
                                key={i['key']}
                                value={i['key']}
                            >
                                {i['key']}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant="contained"
                        sx={{ height: '70%', fontSize: 18 }}
                        onClick={() => {
                            if (!hospital) {
                                notifyInfo('กรุณาเลือกโรงพยาบาล')
                                return
                            }

                            if (!department) {
                                notifyInfo('กรุณาเลือกแผนก')
                                return
                            }
                            if (!location) {
                                notifyInfo('กรุณาเลือกสถานที่')
                                return
                            }

                            clickOpenModal()
                        }}
                    >เลือกห้อง</Button>
                </FormControl>

            </Box>

            <Box sx={{
                backgroundColor: '#FFFFFF',
                border: '1px solid #E1E3E9',
                borderRadius: 3,
                padding: 4,
                display: 'flex',
                gridGap: 30,
                alignItems: 'center',
                '@media (max-width: 1023px)': {
                    padding: 2
                },
                '@media (max-width: 800px)': {
                    padding: 2
                }
            }}>
                {/* <img className={styles.Img} src={RamaIcon} /> */}
                <Typography sx={{
                    fontWeight: 700, fontSize: 40, color: '#222222',
                    '@media (max-width: 800px)': {
                        fontSize: 20
                    }
                }}>{hospital['hospital_name']} {roomOn ? convertGroupRoom(roomOn) : null}</Typography>
            </Box>

            <Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{
                                    backgroundColor: '#1B8DCD',
                                    color: '#ffffff',
                                    borderRadius: 4,
                                    border: '4px solid #ffffff',
                                    fontSize: 40,
                                    textAlign: 'center',
                                    padding: '40px 10',
                                    '@media (max-width: 800px)': {
                                        fontSize: 20,
                                        padding: 2,
                                    }
                                }}>ห้อง</TableCell>
                                <TableCell sx={{
                                    backgroundColor: '#49BD86',
                                    color: '#ffffff',
                                    borderRadius: 4,
                                    border: '4px solid #ffffff',
                                    fontSize: 40,
                                    textAlign: 'center',
                                    padding: '20px 0',
                                    '@media (max-width: 800px)': {
                                        fontSize: 20,
                                        padding: 2,
                                    }
                                }}>คิวเข้าห้องตรวจ</TableCell>
                                <TableCell sx={{
                                    backgroundColor: '#D9B021',
                                    color: '#ffffff',
                                    borderRadius: 4,
                                    border: '4px solid #ffffff',
                                    fontSize: 40,
                                    textAlign: 'center',
                                    padding: '40px 0',
                                    '@media (max-width: 800px)': {
                                        fontSize: 20,
                                        padding: 2,
                                    }
                                }}>คิวที่รอหน้าห้องตรวจ</TableCell>
                                <TableCell sx={{
                                    backgroundColor: '#E39925',
                                    color: '#ffffff',
                                    borderRadius: 4,
                                    border: '4px solid #ffffff',
                                    fontSize: 40,
                                    textAlign: 'center',
                                    padding: '40px 10',
                                    '@media (max-width: 800px)': {
                                        fontSize: 20,
                                        padding: 2,
                                    }
                                }}>รวม</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{
                                    backgroundColor: '#D0EAF9',
                                    color: '#222222',
                                    borderRadius: 4,
                                    border: '4px solid #ffffff',
                                    fontSize: 40,
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    padding: 6,
                                    '@media (max-width: 800px)': {
                                        fontSize: 20,
                                        padding: 2,
                                    }
                                }}>
                                    {
                                        queueRoomCurrent['room_1']['room_data']?.['room_name'] ?
                                            queueRoomCurrent['room_1']['room_data']['room_name']
                                            : '-'}
                                </TableCell>
                                <TableCell sx={{
                                    backgroundColor: '#00D0FF17',
                                    color: '#222222',
                                    borderRadius: 4,
                                    border: '4px solid #ffffff',
                                    fontSize: 40,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    '@media (max-width: 800px)': {
                                        fontSize: 20,
                                        padding: 2,
                                    }
                                }}>{queueRoomCurrent['room_1']['current_queue'] ?
                                    groupCodeLine(
                                        queueRoomCurrent['room_1']['current_queue']['clinic_code'],
                                        queueRoomCurrent['room_1']['current_queue']['queue'],
                                    ) : '-'}
                                </TableCell>
                                <TableCell sx={{
                                    backgroundColor: '#FCF5BC',
                                    color: '#222222',
                                    borderRadius: 4,
                                    border: '4px solid #ffffff',
                                    fontSize: 40,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    '@media (max-width: 800px)': {
                                        fontSize: 20,
                                        padding: 2,
                                    }
                                }}>
                                    <Grid container spacing={2} justifyContent="center">{
                                        queueRoomCurrent['room_1']['appointment_list'].length > 0 ?
                                            queueRoomCurrent['room_1']['appointment_list'].map((item) => {
                                                return (
                                                    <Grid item xs={6} sm={3} md={3} lg={3}>
                                                        {groupCodeLine(item['clinic_code'], item['queue'])}
                                                    </Grid>
                                                )
                                            }) : '-'
                                    }
                                    </Grid>
                                </TableCell>
                                {/* แสดงผลรวม */}
                                <TableCell sx={{
                                    backgroundColor: '#FAECDA',
                                    color: '#222222',
                                    borderRadius: 4,
                                    border: '4px solid #ffffff',
                                    fontSize: 40,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    '@media (max-width: 800px)': {
                                        fontSize: 20,
                                        padding: 2,
                                    }
                                }}>{totalQueueThreeRoom(queueRoomCurrent['room_1'])}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{
                                    backgroundColor: '#D0EAF9',
                                    color: '#222222',
                                    borderRadius: 4,
                                    border: '4px solid #ffffff',
                                    fontSize: 40,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    padding: 6,
                                    '@media (max-width: 800px)': {
                                        fontSize: 20,
                                        padding: 2,
                                    }
                                }}>
                                    {
                                        queueRoomCurrent['room_2']['room_data']?.['room_name'] ?
                                            queueRoomCurrent['room_2']['room_data']['room_name']
                                            : '-'}
                                </TableCell>
                                <TableCell sx={{
                                    backgroundColor: '#00D0FF17',
                                    color: '#222222',
                                    borderRadius: 4,
                                    border: '4px solid #ffffff',
                                    fontSize: 40,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    '@media (max-width: 800px)': {
                                        fontSize: 20,
                                        padding: 2,
                                    }
                                }}>{queueRoomCurrent['room_2']['current_queue'] ?
                                    groupCodeLine(
                                        queueRoomCurrent['room_2']['current_queue']['clinic_code'],
                                        queueRoomCurrent['room_2']['current_queue']['queue'],
                                    ) : '-'}
                                </TableCell>
                                <TableCell sx={{
                                    backgroundColor: '#FCF5BC',
                                    color: '#222222',
                                    borderRadius: 4,
                                    border: '4px solid #ffffff',
                                    fontSize: 40,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    '@media (max-width: 800px)': {
                                        fontSize: 20,
                                        padding: 2,
                                    }
                                }}>
                                    <Grid container spacing={2} justifyContent="center">{
                                        queueRoomCurrent['room_2']['appointment_list'].length > 0 ?
                                            queueRoomCurrent['room_2']['appointment_list'].map((item) => {
                                                return (
                                                    <Grid item xs={6} sm={3} md={3} lg={3}>
                                                        {groupCodeLine(item['clinic_code'], item['queue'])}
                                                    </Grid>
                                                )
                                            }) : '-'
                                    }
                                    </Grid>
                                </TableCell>
                                {/* แสดงผลรวม */}
                                <TableCell sx={{
                                    backgroundColor: '#FAECDA',
                                    color: '#222222',
                                    borderRadius: 4,
                                    border: '4px solid #ffffff',
                                    fontSize: 40,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    '@media (max-width: 800px)': {
                                        fontSize: 20,
                                        padding: 2,
                                    }
                                }}>{totalQueueThreeRoom(queueRoomCurrent['room_2'])}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{
                                    backgroundColor: '#D0EAF9',
                                    color: '#222222',
                                    borderRadius: 4,
                                    border: '4px solid #ffffff',
                                    fontSize: 40,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    padding: 6,
                                    '@media (max-width: 800px)': {
                                        fontSize: 20,
                                        padding: 2,
                                    }
                                }}>
                                    {
                                        queueRoomCurrent['room_3']['room_data']?.['room_name'] ?
                                            queueRoomCurrent['room_3']['room_data']['room_name']
                                            : '-'}
                                </TableCell>
                                <TableCell sx={{
                                    backgroundColor: '#00D0FF17',
                                    color: '#222222',
                                    borderRadius: 4,
                                    border: '4px solid #ffffff',
                                    fontSize: 40,
                                    textAlign: 'center',
                                    '@media (max-width: 800px)': {
                                        fontSize: 20,
                                        padding: 2,
                                    }
                                }}>{queueRoomCurrent['room_3']['current_queue'] ?
                                    groupCodeLine(
                                        queueRoomCurrent['room_3']['current_queue']['clinic_code'],
                                        queueRoomCurrent['room_3']['current_queue']['queue'],
                                    ) : '-'}
                                </TableCell>
                                <TableCell sx={{
                                    backgroundColor: '#FCF5BC',
                                    color: '#222222',
                                    borderRadius: 4,
                                    border: '4px solid #ffffff',
                                    fontSize: 40,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    '@media (max-width: 800px)': {
                                        fontSize: 20,
                                        padding: 2,
                                    }
                                }}>
                                    <Grid container spacing={2} justifyContent="center">
                                        {
                                            queueRoomCurrent['room_3']['appointment_list'].length > 0 ?
                                                queueRoomCurrent['room_3']['appointment_list'].map((item) => {
                                                    return (

                                                        <Grid item xs={6} sm={3} md={3} lg={3}>
                                                            {groupCodeLine(item['clinic_code'], item['queue'])}
                                                        </Grid>

                                                    )
                                                }) : '-'
                                        }
                                    </Grid>
                                </TableCell>
                                {/* แสดงผลรวม */}
                                <TableCell sx={{
                                    backgroundColor: '#FAECDA',
                                    color: '#222222',
                                    borderRadius: 4,
                                    border: '4px solid #ffffff',
                                    fontSize: 40,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    '@media (max-width: 800px)': {
                                        fontSize: 20,
                                        padding: 2,
                                    }
                                }}>{totalQueueThreeRoom(queueRoomCurrent['room_3'])}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {openModal ?
                <MoadlExaminationRoomQueue
                    roomList={optionRoom}
                    onSubmit={(state) => {
                        console.log(state)
                        setRoomOn(state)
                        getCurrentThreeRoomDateQueue(
                            hospital,
                            department,
                            location,
                            state
                        )
                    }}
                    onClose={() => {
                        setOpenModal(false)
                    }}
                />
                : null
            }
        </Box>
    )
}