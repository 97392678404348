import axios from "axios";
import { environment } from '../config.env'
import { register } from "./public_api";

const ENDPOINT_DOMAIN = environment.production ? environment.production_domain : environment.development_domain

const domain = ENDPOINT_DOMAIN

export function findSOS(paramsList) {
    return new Promise(resolve => {
        let url = `${domain}/dashboard-api/api/v1/microservice/sos/findSOS`

        //State=1&order=CreatedDate&sort=asc
        if (paramsList.length > 0) {
            url += `?${paramsList.join("&")}`
        }
        let header = {
            'Content-Type': 'application/json',
            'Platform': 'thaicom-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}


export function updateState(SOSCode) {
    return new Promise(resolve => {
        let url = `${domain}/dashboard-api/api/v1/microservice/sos/updateState`

        let body = {
            SOSCode: SOSCode
        }

        let header = {
            'Content-Type': 'application/json',
            'Platform': 'thaicom-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.put(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}