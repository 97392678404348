import React, { useContext, useEffect, useRef, useState } from "react";
import { Grid, TextField, Button, Box, Typography } from "@mui/material"
import thaiIdIcon from '../../assets/icons/icon-thaiid.svg'
import bgImg from '../../assets/img/bg_login$register.png'
import LoaderAnimation from '../../components/LoaderAnimation';
import * as api from '../../services/ApiOnesId'

import { useNavigate } from "react-router-dom"; //router state


//alert notification
import { notifyError, notifyInfo, notifyWarning } from "../../components/NotifyToast";

import ModalCheckAuthOnesID from "../../components/ModalController/ModalCheckAuthOnesID";

//reduce
import { useDispatch } from 'react-redux'

//reduce auth
import { login as LoginReduce } from '../../store/authSlice';

function LoginOnesId() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [openModal,setOpenModal] = useState(false)

  const [onesDID,setOnesDID] = useState('')
  const [authReqID,setAuthReqID] = useState('')
  const timeOutAuth = useRef(null)
  const [loadingAuth,setLoadingAuth] = useState(false)
  const [loadingLogin,setLoadingLogin] = useState(false)
  const [pass,setPass] = useState(false)
  const [typePath,setTypePath] = useState('')
  const [dataRegister,setDataRegister] = useState([])


  async function toComponentAuth(path, state) {
    navigate(path, {
      state: state
    })
  }

  useEffect(() => {
    if(authReqID){
      if(loadingLogin){
        verifyOnesId()
      }
      if(loadingAuth){
        checkAuth()
      }
    }
  },[authReqID])

  useEffect(() => {
    if(pass){
      clearInterval(timeOutAuth.current)

      if(typePath === 'register'){
        console.log('stop...auth')
        navigate('/register',{ state: { register: 'onesid',data: dataRegister } } )
      }
      if(typePath === 'login'){
        console.log('stop...vertify')
        toComponentAuth('/auth/home')
      }
      if(typePath === 'error'){
        console.log('stop...error')
        return notifyError('ไม่ตอบสนอง')
      }
    }

  },[pass])

  const Auth = async() => {
    if(!onesDID){
      return notifyWarning('กรุณากรอก Ones DID')
    }

    let res = await api.auth(onesDID)
    if(res){
      if(res['data']['code'] === 200){
        setAuthReqID(res['data']['data']['auth_req_id'])
        setLoadingAuth(true)
      }else{
        notifyInfo(res['data']['message'])
      }
    }else{
      notifyError('ไม่ตอบสนอง')
    }
    setOpenModal(true)
  }

  const checkAuth = async() => {
    timeOutAuth.current = setInterval(() => {
      checkAuthen()
    }, 3000)
    return () => {
      clearInterval(timeOutAuth.current)
    }
  }

  const loginOnesId = async() => {
    if(!onesDID){
      return notifyWarning('กรุณากรอก Ones DID')
    }

    let res = await api.loginOnesId(onesDID)
    if(res){
      if(res['data']['code'] === 200){
        setAuthReqID(res['data']['data']['auth_req_id'])
        setLoadingLogin(true)
        setOpenModal(true)
      }else if(res['data']['code'] === 998){
        return notifyInfo(res['data']['message'])
      }else if(res['data']['code'] === 999){
        setOpenModal(true)
        Auth()
      }
    }else{
      notifyError('ไม่ตอบสนอง')
    }
  }

  const verifyOnesId = async() => {
    timeOutAuth.current = setInterval(() => {
      checkVerifyOnesId()
    }, 3000)
    return () => {
      clearInterval(timeOutAuth.current)
    }
  }

  // check auth
  const checkAuthen = async() => {
    // console.log('checking auth.....')

    let res = await api.checkAuth(authReqID)
    if(res){
        // if(res['data']['message'] === "The Ones DiD 3 service is not responding."){
        if(res['data']['code'] === 999){
          setPass(false)
        }else if(res['data']['code'] === 200){
          setTypePath('register')

          let dataRegister = await getUserData(res['data']['data']['access_token'])
          setDataRegister(dataRegister)

          setLoadingAuth(false)
          setOpenModal(false)
          setPass(true)
        }else{
          console.log(res['data']['message'])
          setPass(false)
        }
    }else{
      console.log('check auth ไม่ตอบสนอง')
      setTypePath('error')
      setOpenModal(false)
      setPass(true)
    }
    clearInterval(timeOutAuth.current)
  }

  // check vertify
  const checkVerifyOnesId = async() => {
    // console.log('checking ver....')
    let res = await api.verifyOnesId(onesDID,authReqID)
    if(res){
      if(res['data']['code'] === 401){
        setPass(false)
      }else if(res['data']['code'] === 200){
        setTypePath('login')

        dispatch(LoginReduce(res['data']['data']))
        localStorage.setItem("AccessToken", res['data']['data']['AccessToken'])
        localStorage.setItem("RefreshToken", res['data']['data']['RefreshToken'])

        setLoadingLogin(false)
        setOpenModal(false)
        setPass(true)
      }else{
        console.log(res['data']['message'])
        setPass(false)
      }
    }else{
      console.log('check vertify ไม่ตอบสนอง')
      setTypePath('error')
      setOpenModal(false)
      setPass(true)
    }
    // clearInterval(timeOutAuth.current)
  }

  const getUserData = async(access_token) => {
    let res = await api.getUserData(access_token)
    if(res){
      if(res['data']['code'] === 200){
        // console.log(res['data']['data'])
        const data = {
          titleName: res['data']['data']['additionalInfo']['localizedTitle'],
          fname: res['data']['data']['additionalInfo']['localizedFirstName'],
          lname: res['data']['data']['additionalInfo']['localizedLastName'],
          cid: res['data']['data']['additionalInfo']['identityDocumentNumber'],
          email: res['data']['data']['username']
        }
        return data
      }else{
        notifyInfo(res['data']['message'])
      }
    }else{
      notifyError('ไม่ตอบสนอง')
    }
  }

const contentLogin = {
display:'flex',
justifyContent:'center',
alignItems:'center',
width:'100%',
height: '100vh',
backgroundImage: `url(${bgImg})`,
backgroundRepeat: 'no-repeat',
backgroundSize: 'cover',
}

const boxLogin = {
backgroundColor: '#fff',
width:{xl:'40%',lg:'40%',md:'50%',sm:'90%',xs:'90%'},
padding:{xl:'2rem',lg:'2rem',md:'2rem',sm:'1rem',xs:'1rem'},
borderRadius: '10px',
boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
} 

  return (
    <>
       <LoaderAnimation loading={loading} />
       {
        openModal ? 
        <ModalCheckAuthOnesID
          closeModal={() => {
            setOpenModal(false)
            setAuthReqID('')
            clearInterval(timeOutAuth.current)
          }}
        /> : ''
       }
       <Box sx={contentLogin}>
          <Box sx={boxLogin}>
            <Typography 
                component="p" variant="p" color="primary" 
                sx={{fontWeight:'bold',marginBottom:'0.5rem', fontSize:'calc(16px + 0.5vw)',textAlign:'center'}} 
            >
              เข้าสู่ระบบด้วย OnesID
            </Typography>
            <Box sx={{marginBottom:'1rem'}}>
              <Typography component="p" variant="p" color="primary" sx={{fontWeight:'bold',marginBottom:'0.5rem'}}>Ones DID</Typography>
              <TextField fullWidth placeholder='กรอก Ones DID'
                value={onesDID}
                onChange={(e) => setOnesDID(e.target.value)}
              />
            </Box>
            <Button
                onClick={() => loginOnesId()}
                fullWidth
                variant="contained"
                sx={{padding:'0.5rem 1rem',margin:'0.5rem 0'}}
                >
                ตรวจสอบ
            </Button>    
            <Typography component="p" variant="p" color="secondary" sx={{marginBottom:'0.5rem',textAlign:'center'}}>หรือ</Typography>
            <Button
                onClick={() => navigate('/loginThaiId')}
                fullWidth
                variant="contained"
                sx={{padding:'0.5rem 1rem',marginBottom:'0.5rem'}}
                >
                 <><img src={thaiIdIcon} alt="" style={{display:'inline-block',marginRight:'0.5rem',width:'25px',height:'25px'}}/>{'เข้าสู่ระบบ ด้วย Thai ID'}</>
            </Button>    
            <Button
                onClick={() => navigate('/')}
                fullWidth
                variant="contained"
                sx={{padding:'0.5rem 1rem',marginBottom:'0.5rem'}}
                >
                เข้าสู่ระบบด้วยอีเมล
            </Button>    
          </Box>
       </Box>
    </>
  )
}

export default LoginOnesId