import React, { useState } from 'react'
import { backgroundModal, boxModalV2, buttomPrimary } from './ModalAccessCss'
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Grid, IconButton, Typography, Button } from '@mui/material';
import videocall from '../../assets/icons/videocall_icon.svg'
import { notifyError, notifyInfo } from '../NotifyToast';
import * as api from '../../services/ApiRecordPatient'
import LoaderAnimation from '../LoaderAnimation';

function ModalSharedConference({ onClose, data }) {

  const [loading, setLoading] = useState(false)

  const shareConference = async (body) => {
    setLoading(true)
    let res = await api.shareConferrence(body)
    setLoading(false)
    if (res) {
      if (res['data']['code'] === 200) {
        onClose()
      } else {
        notifyInfo(res['data']['message'])
      }
    } else {
      notifyError('ไม่ตอบสนอง')
    }
  }

  const clickShareConference = () => {
    shareConference(data)
  }

  return (
    <>
      <LoaderAnimation loading={loading} />
      <Box sx={backgroundModal}>
        <Box sx={boxModalV2}>
          <Box sx={{ textAlign: 'end', '&:hover': { cursor: 'pointer' } }}>
            <IconButton onClick={() => { onClose() }}><ClearIcon /></IconButton>
          </Box>

          <div style={{width:'165px',height:'85px'}}></div>
          {/* <img src={videocall} alt='' style={{ display: 'block', margin: '0 auto 1rem auto',width:'50%' }} /> */}

          <Typography
            color={'#747676'}
            sx={{
              textAlign: 'center',
              fontSize: 'calc(15px + 0.10vw)',
              fontWeight: 'bold',
              marginBottom: '1rem'
            }}>Meeting ID : {data['meetingID']}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center', gridGap: '1rem', margin: '1rem 0' }}>
            <Button onClick={() => { clickShareConference() }}
              fullWidth
              variant="contained"
              sx={buttomPrimary}
            >
              {'แชร์ลิ้งค์ไปยังผู้ป่วย'}
            </Button>

          </Box>
        </Box>
      </Box>
    </>
  )
}

export default ModalSharedConference