import React from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import {backgroundModal,boxModal,buttomPrimary,buttomSecondary} from './ModalAccessCss'
import { Box, Button, TextField,Typography,Grid } from "@mui/material";


function ModalEditMedicine(props) {
    const {closeModal,submitForm,data,onChange} = props


  return (
    <Box sx={backgroundModal}>
        <Box sx={boxModal}>
            <Box sx={{textAlign:'end','&:hover': {cursor:'pointer'}}} onClick={closeModal}><ClearIcon/></Box>
            <Typography component="h1" variant="h1" color={'#1F2BA6'} sx={{fontSize:'18px',fontWeight:'bold'}}>แก้ไขชื่อยา</Typography> 
            <hr style={{margin:'1.5rem 0',width:'100%'}}/>
            <Grid container spacing={3}>
                <Grid item xs={3.5}>
                    <Typography component="p" variant="p" color={'#1F2BA6'} sx={{fontWeight:'bold'}}>ชื่อยา</Typography> 
                </Grid>
                <Grid item xs={8.5}>
                    <TextField fullWidth placeholder="ชื่อยา" variant="outlined" 
                        value={data['drug_name']}
                        name='drug_name'
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3.5}>
                    <Typography component="p" variant="p" color={'#1F2BA6'} sx={{fontWeight:'bold'}}>คำอธิบาย</Typography> 
                </Grid>
                <Grid item xs={8.5}>
                    <TextField fullWidth placeholder="คำอธิบาย" variant="outlined" 
                        value={data['drug_detail']}
                        name='drug_detail'
                        multiline
                        rows={4}
                        onChange={onChange}
                    />
                </Grid>
            </Grid>
            <Box sx={{display:'flex',justifyContent:'center',gridGap:'1rem',marginTop:'2rem'}}>         
                <Button 
                    onClick={closeModal}
                    fullWidth
                    variant="contained"
                    sx={buttomSecondary}
                    >
                    {'ยกเลิก'}
                </Button>
                <Button 
                    onClick={submitForm}
                    fullWidth
                    variant="contained"
                    sx={buttomPrimary}
                    >
                    {'บันทึก'}
                </Button>            
            </Box>
        </Box>
    </Box>
  )
}

export default ModalEditMedicine