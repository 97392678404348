import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Select, FormControl, Stack, Pagination, MenuItem, Typography  } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as api from '../../../services/ApiConsultDoctor'
import * as moment from 'moment';
import LoaderAnimation from '../../../components/LoaderAnimation'
import { notifyError, notifyInfo } from "../../../components/NotifyToast";
import { useDispatch } from "react-redux";
import { logout } from './../../../store/authSlice';


export default function DailyAppointments() {

    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [dataDaily, setDataDaily] = useState([])

    const [numPage, setNumPage] = useState(5)

    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [totalListPage, setTotalListPage] = useState(0)
    const [showList, setShowList] = useState([])

    // useEffect(() => {
    //     getCurrentDateAppointment()
    // }, [])

    useEffect(() => {
        let start = page * numPage - numPage
        setShowList(dataDaily.slice(start, start + numPage))
    }, [page, dataDaily])

    // useEffect(() => {
    //     if(search) {
    //         setShowList(dataDaily)
    //     } else {
    //         let start = page * numPage - numPage
    //         setShowList(dataDaily.slice(start, start + numPage))
    //     }
    // },[search])

    useEffect(() => {
        getCurrentDateAppointment()
    }, [numPage])

    useEffect(() => {
        setPage(1)
    }, [numPage])

    const getCurrentDateAppointment = async () => { // ตาราง
        setLoading(true)
        let res = await api.getCurrentDateAppointment()
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setDataDaily(res['data']['appointment_list'])
                setMaxPage((Math.ceil(res['data']['appointment_list'].length / numPage)))
                setTotalListPage(res['data']['appointment_list'].length)
            } else {
                if (res['data']['code'] === 401) {
                    localStorage.clear()
                    dispatch(logout())
                } else {
                    notifyInfo(res['data']['message'])
                }
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const printAppointment = async (_id) => {
        setLoading(true)
        let res = await api.printAppointment(_id)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                getCurrentDateAppointment()
            } else {
                if (res['data']['code'] === 401) {
                    localStorage.clear()
                    dispatch(logout())
                } else {
                    notifyInfo(res['data']['message'])
                }
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const groupCodeLine = (clinic_code, queue_code) => {
        let queue_str = String(queue_code);

        if (queue_str.length < 2) {
            queue_str = '000' + queue_str;
        } else if (queue_str.length < 3) {
            queue_str = '00' + queue_str;
        } else if (queue_str.length < 4) {
            queue_str = '0' + queue_str;
        }

        return clinic_code + queue_str;
    }


    const handleCheckIn = (item, id) => {
        if (item) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ color: '#ffffff', backgroundColor: '#1F2BA6', width: '120px', height: '40px', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                        เช็คอินแล้ว
                    </div>
                </div>
            )
        } else if (item === undefined) {
            return (
                <>
                    <Button variant="outlined" onClick={() => { clickChechIn(id) }}>ยังไม่ได้เช็คอิน</Button>
                </>
            )
        }
    }

    const clickChechIn = (id) => {
        printAppointment(id)
    }

    const handlePageChange = (event, page) => {
        setPage(page)
    }


    return (
        <>
            <LoaderAnimation loading={loading} />
            <Box sx={{
                backgroundColor: '#FFFFFF',
                border: '1px solid #E1E3E9',
                borderRadius: 2,
                marginTop: 2,
                padding: 2,
                fontSize: 18,
                '@media (max-width: 1023px)': {
                    marginTop: 2,
                    padding: 1,
                    fontSize: 16,
                },
                '@media (max-width: 800px)': {
                    marginTop: 1,
                    padding: 1,
                    fontSize: 14
                }
            }}>
                <div style={{ color: '#1F2BA6', fontWeight: 'bold' }}>รายชื่อนัดประจำวัน</div>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    gridGap: 20,
                    '@media (max-width: 800px)': {
                        justifyContent: 'center',
                        gridGap: 10
                    }
                }}>
                    <div>จำนวนทั้งหมด {totalListPage}</div>
                    <TextField
                        size="small"
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value)
                        }}
                    >
                    </TextField>
                </Box>
                <TableContainer>
                    <Table sx={{ marginTop: 3, minWidth: '1200px' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ color: '#1F2BA6', textAlign: 'center', fontWeight: 'bold' }}>#</TableCell>
                                <TableCell style={{ color: '#1F2BA6', textAlign: 'center', fontWeight: 'bold' }}>เวลาซักประวัติ</TableCell>
                                <TableCell style={{ color: '#1F2BA6', textAlign: 'center', fontWeight: 'bold' }}>รหัสนำทาง</TableCell>
                                <TableCell style={{ color: '#1F2BA6', textAlign: 'center', fontWeight: 'bold' }}>เวลาส่งเข้าห้องตรวจ</TableCell>
                                <TableCell style={{ color: '#1F2BA6', textAlign: 'center', fontWeight: 'bold' }}>ห้องตรวจ</TableCell>
                                <TableCell style={{ color: '#1F2BA6', textAlign: 'center', fontWeight: 'bold' }}>ชื่อคลินิก</TableCell>
                                <TableCell style={{ color: '#1F2BA6', textAlign: 'center', fontWeight: 'bold' }}>เลขบัตรประจำตัวประชาชน</TableCell>
                                <TableCell style={{ color: '#1F2BA6', fontWeight: 'bold' }}>ชื่อ</TableCell>
                                <TableCell style={{ color: '#1F2BA6', textAlign: 'center', fontWeight: 'bold' }}>เช็คอิน</TableCell>
                            </TableRow>
                        </TableHead>
                        {
                            showList.length === 0 ? 
                            <TableBody>
                                <TableRow>
                                    <TableCell 
                                        colSpan={12}
                                        align='center'
                                    >
                                        <Typography 
                                        component={"p"}
                                        sx={{margin:'1rem 0'}}
                                        >
                                            ไม่พบข้อมูล
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody> : 
                            <>
                                {showList.filter((item) => {
                                    return (item?.['fname'] + item?.['lname']).includes(search)
                                }).map((i, idx) => (
                                    <TableBody key={idx}>
                                        <TableRow>
                                            <TableCell style={{ color: '#747676', textAlign: 'center' }}>{numPage * (page - 1) + 1 + idx}</TableCell>
                                            <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['appointment_date_time'] ? moment(i['appointment_date_time']).format('HH:mm') + ' น.' : '-'}</TableCell>
                                            <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['queue'] ? groupCodeLine(i['clinic_code'], i['queue']) : 'รอเช็คอิน'}</TableCell>
                                            <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['assign_room_timestamp'] ? moment(i['assign_room_timestamp']).format('HH:mm') + ' น.' : '-'}</TableCell>
                                            <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['room_name'] ? i['room_name'] : '-'}</TableCell>
                                            <TableCell style={{ color: '#747676' }}>{i['clinic_name']}</TableCell>
                                            <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['pid']}</TableCell>
                                            <TableCell style={{ color: '#747676' }}>{i['fname'] + ' ' + i['lname']}</TableCell>
                                            <TableCell style={{ color: '#747676', textAlign: 'center' }}>{handleCheckIn(i['queue'], i['_id'])}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                ))}
                            </>
                        }
                    </Table>
                </TableContainer>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: 2
                }}>
                    <Box sx={{ width: '100px' }}>
                        <FormControl fullWidth>
                            <Select
                                value={numPage}
                                onChange={(e) => {
                                    const {
                                        target: { value },
                                    } = e;
                                    setNumPage(value)
                                }}
                                size='small'
                                sx={{ width: '100px', fontSize: '14px' }}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Stack spacing={2}>
                        <Pagination
                            count={maxPage}
                            page={page}
                            onChange={handlePageChange}
                            shape="rounded"
                            color="primary"
                        />
                    </Stack>
                </Box>

            </Box>
        </>
    )
}