import { InputAdornment, Box, Button, FormControl, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Stack, Pagination, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalRecordKeeping from "../../components/ModalController/ModalRecordKeeping";
import LoaderAnimation from '../../components/LoaderAnimation'
import * as api from '../../services/ApiConsultDoctor'
import moment from "moment";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import dayjs from 'dayjs';
import IconButton from '@mui/material/IconButton';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ModalCalendar from '../../components/ModalController/ModalCalendar'
import { notifyError, notifyInfo, notifySuccess } from "../../components/NotifyToast";

export const buttonPrimary = {
    backgroundColor: '#00D0FF17',
    border: '1px solid #1F2BA6',
    padding: '0.5rem 1rem',
    width: 'auto',
    borderRadius: '8px',
    color: '#1F2BA6',
    boxShadow: 'none',
    width: '100%',
    '&:hover': {
        backgroundColor: '#00D0FF17',
        color: '#1F2BA6',
        boxShadow: 'none'
    },
}

export const buttonInPrimary = {
    backgroundColor: '#FBFBFB',
    border: 'none',
    padding: '0.5rem 1rem',
    width: 'auto',
    color: '#747676',
    width: '100%',
    boxShadow: 'none',
    '&:hover': {
        backgroundColor: '#FBFBFB',
        color: '#747676',
        boxShadow: 'none'
    },
}

export const history = {
    backgroundColor: '#00D0FF17',
    borderRadius: 1,
    '&:hover': {
        backgroundColor: '#eee4f7',
    },
}

export const buttom_primary = {
    backgroundColor: '#1F2BA6',
    border: '1px solid #1F2BA6',
    padding: '0.5rem 1rem',
    width: '150px',
     borderRadius: 1,
    color: '#fff',
    boxShadow:'none',
    '&:hover': {
      backgroundColor:'#1F2BA6', 
      boxShadow:'none',
    }
}

export const buttom_secondary = {
    backgroundColor: '#00D0FF17',
    border: '1px solid #00D0FF17',
    padding: '0.5rem 1rem',
    width: '150px',
     borderRadius: 1,
    color: '#1F2BA6',
    boxShadow:'none',
    '&:hover': {
      backgroundColor:'#eee4f7', 
      boxShadow:'none',
    }
}

export default function AppointmentPatients() {
    const [value, setValue] = useState(0);

    return (
        <>
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                fontSize: 18,
                gridGap:'1rem',
                '@media (max-width: 1023px)': {
                    fontSize: 14,
                },
                '@media (max-width: 800px)': {
                    gridTemplateColumns: '1fr',
                    fontSize: 12,
                }
            }}>
                <Button
                    onClick={() => { setValue(0) }}
                    sx={value === 0 ? buttonPrimary : buttonInPrimary}
                >ผู้ป่วยนัดจากระบบ</Button>
                <Button
                    onClick={() => { setValue(1) }}
                    sx={value === 1 ? buttonPrimary : buttonInPrimary}
                >ผู้ป่วยนัดจากโรงพยาบาล</Button>
            </Box>
            {value === 0 ?
                <>
                    <SystemPatients />
                </> : null
            }
            {value === 1 ?
                <>
                    <HospitalPatients />
                </> : null
            }
        </>
    )
}

const SystemPatients = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const [hospital, setHospital] = useState('')
    const [optionHospital, setOptionHospital] = useState([])
    const [department, setDepartment] = useState('')
    const [optionDepartment, setOptionDepartment] = useState([])
    const [namePatient, setNamePatient] = useState('')
    const [pid, setPid] = useState('')
    const [date, setDate] = useState(dayjs(moment(new Date()).format('YYYY-MM-DD')))

    const [dataSystem, setDataSystem] = useState([])

    const [openModal, setOpenModal] = useState(false)
    const [docNumber, setDocNumber] = useState('')

    const [numPage, setNumPage] = useState(5)
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [showList, setShowList] = useState([])

    const [disabledDay, setDisabledDay] = useState([])
    const [openCalendar, setOpenCalendar] = useState(false)

    const [dataAll, setDataAll] = useState([])
    const [dataHos, setDataHos] = useState([])

    const [room, setRoom] = useState('')
    const [optionRoom, setOptionRoom] = useState([])

    const [itemFindIndex, setItemFindIndex] = useState(null)

    useEffect(() => {
        getHospital()
        findDepartment()
    }, [])

    useEffect(() => {
        setShowList([])
        findDepartment()
    }, [hospital])

    useEffect(() => {
        findRoom()
    }, [department])


    useEffect(() => {
        findTelemedicine()
    }, [numPage, hospital, department])

    useEffect(() => {
        setPage(1)
    }, [numPage])

    useEffect(() => {
        let start = page * numPage - numPage
        setShowList(searchData().slice(start, start + numPage))
    }, [page, dataSystem, namePatient, pid])

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const systempatientAndpointofcare = (pid) => {
        window.open(`/systemPatientInformation?PID=${pid}`)
    }

    const openModalRecondKeeping = (DocNumber, ItemFindIndex) => {
        setDocNumber(DocNumber)
        setOpenModal(true)
        setItemFindIndex(ItemFindIndex)
    }

    const openTreatmentHistory = (pid, DocNumber) => {
        navigate('/treatmentHistory', {
            state: {
                PID: pid,
                DocNumber: DocNumber
            }
        })
    }

    const getHospital = async () => {
        setLoading(true)
        let res = await api.findDepartment()
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setDataAll(res['data']['data'])
                const List = []
                for (let i = 0; i < res['data']['data'].length; i++) {
                    if (res['data']['data'][i]['HospitalCode'] === localStorage.getItem('hospital_code')) {
                        List.push(res['data']['data'][i]['Clinic'])
                    }
                }
                let data_new = List.reduce((prev, cur) => prev.concat(cur), [])
                setDataHos(data_new)
                const unique = [...new Map(data_new.map((m) => [m.ClinicCode, m])).values()] //กรองชื่อซ้ำ
                setOptionHospital(unique)
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const findDepartment = async () => {
        let code = []
        for (let i = 0; i < dataHos.length; i++) {
            if (dataHos[i]['ClinicCode'] === hospital) {
                code.push(dataHos[i]['DepartmentCode'])
            }
        }
        const List = dataAll.filter(item => {
            return code?.find((menuItem) => menuItem === item['DepartmentCode'])
        })
        setOptionDepartment(List)
    }

    const findRoom = () => {
        let ListRoom = []
        for (let i = 0; i < optionDepartment.length; i++) {
            if (optionDepartment[i]['DepartmentCode'] === department) {
                ListRoom.push(optionDepartment[i]['RoomConference'])
            }
        }
        const unique = [...new Map(ListRoom[0]?.map((m) => [m.RoomConferenceName, m])).values()] //กรองชื่อซ้ำ
        setOptionRoom(unique)
        console.log(unique)
    }

    const clickConference = () => {
        // const url = optionRoom[0][room-1]
        // window.open(url['ConferenceURL'], '_system')
        let RoomLink = []
        for (let i = 0; i < optionRoom.length; i++) {
            if (optionRoom[i]['RoomConferenceName'] === room) {
                RoomLink.push(optionRoom[i]['ConferenceURL'])
            }
        }
        window.open(RoomLink, '_system')
    }

    const findTelemedicine = async () => {
        let dateNow = moment(date['$d']).format('YYYY-MM-DD')

        setLoading(true)
        let res = await api.findTelemedicine(dateNow)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                if (hospital === ' ') {
                    setDataSystem(res['data']['data'])
                    setMaxPage((Math.ceil(res['data']['data'].length / numPage)))
                } else if (hospital !== '' && (department === '' || department === ' ')) {
                    const List = []
                    for (let i = 0; i < res['data']['data'].length; i++) {
                        if (res['data']['data'][i]['HospitalCodeTo'] === hospital) {
                            List.push(res['data']['data'][i])
                        }
                    }
                    setDataSystem(List)
                    setMaxPage((Math.ceil(List.length / numPage)))
                } else if (hospital !== '' && department !== '') {
                    const List = []
                    for (let i = 0; i < res['data']['data'].length; i++) {
                        if (res['data']['data'][i]['DepartmentCode'] === department && res['data']['data'][i]['HospitalCodeTo'] === hospital) {
                            List.push(res['data']['data'][i])
                        }
                    }
                    setDataSystem(List)
                    setMaxPage((Math.ceil(List.length / numPage)))
                } else {
                    setDataSystem(res['data']['data'])
                    setMaxPage((Math.ceil(res['data']['data'].length / numPage)))
                }

            } else {
                setDataSystem([])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const cancelTelemedicine = async (DocNumber) => {
        setLoading(true)
        let res = await api.cancelTelemedicine(DocNumber)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                notifySuccess('ลบนัดหมายสำเร็จ')
                findTelemedicine()
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const deleteAppointment = (DocNumber) => {
        cancelTelemedicine(DocNumber)
    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }
        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    const searchData = () => {
        return dataSystem.filter((item) =>
            (namePatient === '' || item['Name'].includes(namePatient)) &&
            (pid === '' || item['PID'].includes(pid))
        )
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            {openCalendar &&
                <ModalCalendar
                    onClose={() => { setOpenCalendar(false) }}
                    onFinished={() => {
                        findTelemedicine()
                    }}
                    value={date ? dayjs(moment(date['$d']).format('YYYY-MM-DD')) : dayjs(moment(new Date()).format('YYYY-MM-DD'))}
                    onChange={(e) => setDate(e)}
                    disableDay={disabledDay}
                />
            }
            <Box sx={{
                fontSize: 18,
                '@media (max-width: 1023px)': {
                    fontSize: 14,
                },
                '@media (max-width: 800px)': {
                    fontSize: 12,
                }
            }}>
                <Box sx={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #E1E3E9',
                    borderRadius: 2,
                    marginTop: 2,
                    padding: 2,
                    '@media (max-width: 1023px)': {
                        marginTop: 2,
                        padding: 1
                    },
                    '@media (max-width: 800px)': {
                        marginTop: 1,
                        padding: 1
                    }
                }}>
                    <div style={{ color: '#1F2BA6' }} >Filters</div>
                    <Box sx={{
                        padding: '10px 0',
                        display: 'grid',
                        gridTemplateColumns: '2fr 2fr 2fr 1fr',
                        gridGap: 30,
                        alignItems: 'flex-end',
                        '@media (max-width: 1100px)': {
                            padding: '5px 0',
                            gridTemplateColumns: '1fr 1fr',
                            gridGap: 20
                        },
                        '@media (max-width: 800px)': {
                            padding: '5px 20px',
                            gridTemplateColumns: '1fr',
                            gridGap: 10
                        }
                    }}>
                        <FormControl>
                            <div style={{ color: '#1F2BA6' }} >โรงพยาบาลที่ขอรับคำปรึกษา</div>
                            <Select size="small"
                                value={hospital || ' '}
                                onChange={(event) => {
                                    const {
                                        target: { value },
                                    } = event;
                                    setHospital(value)
                                    setDepartment('')
                                    setOptionDepartment([])
                                    setRoom('')
                                    setOptionRoom([])
                                    console.log(value)
                                }}
                            >
                                <MenuItem value=" ">เลือกโรงพยาบาล</MenuItem>
                                {optionHospital.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        value={item['ClinicCode']}
                                    >
                                        {item['ClinicName']}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <div style={{ color: '#1F2BA6' }} >แผนกที่เข้ารับบริการ</div>
                            <Select size="small"
                                disabled={hospital === '' ? true : null}
                                value={department || ' '}
                                onChange={(event) => {
                                    const {
                                        target: { value },
                                    } = event;
                                    setDepartment(value)
                                    setRoom('')
                                    setOptionRoom([])
                                    console.log(value)
                                }}
                            >
                                <MenuItem value=" ">เลือกแผนก</MenuItem>
                                {optionDepartment.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        value={item['DepartmentCode']}
                                    >
                                        {item['DepartmentName']}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <div style={{ color: '#1F2BA6' }} >เลือกห้อง</div>
                            <Select size="small"
                                disabled={department === '' ? true : null}
                                value={room || ' '}
                                onChange={(event) => {
                                    const {
                                        target: { value },
                                    } = event;
                                    setRoom(value)
                                }}
                            >
                                <MenuItem value=" ">ห้อง</MenuItem>
                                {optionRoom?.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        value={item['RoomConferenceName']}
                                    >
                                        {item['RoomConferenceName']}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box sx={{
                            '@media (max-width: 800px)': {
                                display: 'flex',
                                justifyContent: 'center'
                            }
                        }}>
                            <Button
                                variant="contained"
                                disabled={room === '' ? true : null}
                                onClick={() => { clickConference() }}
                            >Conference</Button>
                        </Box>

                    </Box>
                </Box>

                <Box sx={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #E1E3E9',
                    borderRadius: 2,
                    marginTop: 2,
                    padding: 2,
                    '@media (max-width: 1023px)': {
                        marginTop: 2,
                        padding: 1
                    },
                    '@media (max-width: 800px)': {
                        marginTop: 1,
                        padding: 1
                    }
                }}>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '2fr 2fr 2fr 1fr',
                        gridGap: 30,
                        alignItems: 'end',
                        '@media (max-width: 1023px)': {
                            gridTemplateColumns: '1fr 1fr',
                            gridGap: 20,
                        },
                        '@media (max-width: 800px)': {
                            gridTemplateColumns: '1fr',
                            gridGap: 10,
                        }
                    }}>
                        <FormControl>
                            <div style={{ color: '#1F2BA6' }} >ค้นหาโดยวันที่</div>
                            <TextField
                                size='small'
                                value={convertDate(date['$d'])}
                                disabled
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton onClick={() => {
                                                setOpenCalendar(true)
                                            }}>
                                                <CalendarTodayIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }} />
                        </FormControl>

                        <FormControl>
                            <div style={{ color: '#1F2BA6' }} >ค้นหาโดยชื่อ-นามสกุล</div>
                            <TextField
                                size='small'
                                placeholder={'กรุณาระบุชื่อ-นามสกุลของผู้ป่วย'}
                                value={namePatient}
                                onChange={(event) => {
                                    const {
                                        target: { value },
                                    } = event;
                                    setNamePatient(value)
                                }}
                            ></TextField>
                        </FormControl>

                        <FormControl>
                            <div style={{ color: '#1F2BA6' }} >ค้นหาโดยเลขประจำตัวประชาชน</div>
                            <TextField
                                size='small'
                                placeholder={'กรุณาระบุเลขประจำตัวประชาชนของผู้ป่วย'}
                                value={pid}
                                onChange={(event) => {
                                    const {
                                        target: { value },
                                    } = event;
                                    const maxLength = 13;
                                    const numericRegex = /^[0-9]*$/;
                                    if (value.length <= maxLength && numericRegex.test(value)) {
                                        setPid(value)
                                    }
                                }}
                            ></TextField>
                        </FormControl>

                        <div style={{ color: '#747676' }}>จำนวนทั้งหมด {dataSystem.length}</div>
                    </Box>

                    <TableContainer>
                        <Table sx={{ marginTop: 3, minWidth: '1400px' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: '#1F2BA6', textAlign: 'center' }}>#</TableCell>
                                    <TableCell sx={{ color: '#1F2BA6' }}>โรงพยาบาลขอรับคำปรึกษา</TableCell>
                                    <TableCell sx={{ color: '#1F2BA6', textAlign: 'center' }}>แผนก</TableCell>
                                    <TableCell sx={{ color: '#1F2BA6' }}>รายชื่อ</TableCell>
                                    <TableCell sx={{ color: '#1F2BA6', textAlign: 'center' }}>วันและเวลานัดหมาย</TableCell>
                                    <TableCell sx={{ color: '#1F2BA6', textAlign: 'center' }}>ประวัติการแพทย์</TableCell>
                                    <TableCell sx={{ color: '#1F2BA6', textAlign: 'center' }}>บันทึกการรักษา</TableCell>
                                    <TableCell sx={{ color: '#1F2BA6', textAlign: 'center' }}>ลบนัดหมาย</TableCell>
                                </TableRow>
                            </TableHead>
                            {showList.length === 0 ?
                                <TableBody>
                                    <TableRow>
                                        <TableCell 
                                            colSpan={12}
                                            align='center'
                                        >
                                            <Typography 
                                            component={"p"}
                                            sx={{margin:'1rem 0'}}
                                            >
                                                ไม่พบข้อมูล
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                                : null
                            }

                            {showList.map((i, idx) => (
                                <TableBody key={idx}>
                                    <TableRow>
                                        <TableCell sx={{ color: '#747676', textAlign: 'center' }}>{1 + idx}</TableCell>
                                        <TableCell sx={{ color: '#747676' }}>{i['HospitalNameTo']}</TableCell>
                                        <TableCell sx={{ color: '#747676', textAlign: 'center' }}>{i['DepartmentName'] === null ? '-' : i['DepartmentName']}</TableCell>
                                        <TableCell sx={{ color: '#747676' }}>{i['Name']}</TableCell>
                                        <TableCell sx={{ color: '#747676', textAlign: 'center' }}>{convertDate(i['AppointDate']) + ' ' + i['AppointTime'] + 'น.'}</TableCell>
                                        <TableCell sx={{ color: '#747676', textAlign: 'center' }}>
                                            <Button
                                                sx={history}
                                                onClick={() => { systempatientAndpointofcare(i['PID']) }}
                                            >ดูประวัติการแพทย์
                                            </Button>
                                        </TableCell>
                                        <TableCell sx={{ color: '#747676', display:'flex', justifyContent:'center', gridGap:'1rem'}}>
                                            <Button 
                                                sx={i['Order'].length > 0 ? buttom_secondary : buttom_primary }
                                                onClick={() => { openModalRecondKeeping(i['DocNumber'], i) }}
                                                style={{fontSize: i['Order'].length > 0 ? 'calc(13px + 0.05vw)' : ''}}
                                            >{i['Order'].length > 0 ? "เปิดบันทึกการรักษา" : "บันทึกการรักษา"}</Button>
                                            <Button 
                                                sx={buttom_primary}
                                                onClick={() => { openTreatmentHistory(i['PID'], i['DocNumber']) }}
                                            >ประวัติการรักษา</Button>
                                        </TableCell>
                                        <TableCell sx={{ color: '#1F2BA6', textAlign: 'center' }}>
                                            <DeleteRoundedIcon style={{ cursor: 'pointer' }} onClick={() => { deleteAppointment(i['DocNumber']) }} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ))}


                        </Table>
                    </TableContainer>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: 2
                    }}>
                        <Box sx={{ width: '100px' }}>
                            <FormControl fullWidth>
                                <Select
                                    value={numPage}
                                    onChange={(e) => {
                                        const {
                                            target: { value },
                                        } = e;
                                        setNumPage(value)
                                    }}
                                    size='small'
                                    sx={{ width: '100px', fontSize: '14px' }}
                                >
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Stack spacing={2}>
                            <Pagination
                                count={maxPage}
                                page={page}
                                onChange={handlePageChange}
                                shape="rounded"
                                color="primary"
                            />
                        </Stack>
                    </Box>

                    {openModal ?
                        <>
                            <ModalRecordKeeping
                                onClose={() => { setOpenModal(false) }}
                                DocNumber={docNumber}
                                item={itemFindIndex}
                                getdata={() => findTelemedicine()}
                            />
                        </>
                        : null
                    }
                </Box >

            </Box >
        </>

    )
}

const HospitalPatients = () => {

    const [loading, setLoading] = useState(false)

    const [hospital, setHospital] = useState('')
    const [optionHospital, setOptionHospital] = useState([])
    const [department, setDepartment] = useState('')
    const [optionDepartment, setOptionDepartment] = useState([])

    const [dataAll, setDataAll] = useState([])
    const [dataHos, setDataHos] = useState([])

    const [room, setRoom] = useState('')
    const [optionRoom, setOptionRoom] = useState([])

    useEffect(() => {
        getHospital()
    }, [])

    useEffect(() => {
        findDepartment()
    }, [hospital])

    useEffect(() => {
        findRoom()
    }, [department])

    const getHospital = async () => {
        setLoading(true)
        let res = await api.findDepartment()
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setDataAll(res['data']['data'])
                const List = []
                for (let i = 0; i < res['data']['data'].length; i++) {
                    if (res['data']['data'][i]['HospitalCode'] === localStorage.getItem('hospital_code')) {
                        List.push(res['data']['data'][i]['Clinic'])
                    }
                }
                let data_new = List.reduce((prev, cur) => prev.concat(cur), [])
                setDataHos(data_new)
                const unique = [...new Map(data_new.map((m) => [m.ClinicCode, m])).values()] //กรองชื่อซ้ำ
                setOptionHospital(unique)
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const findDepartment = async () => {
        let code = []
        for (let i = 0; i < dataHos.length; i++) {
            if (dataHos[i]['ClinicCode'] === hospital) {
                code.push(dataHos[i]['DepartmentCode'])
            }
        }
        const List = dataAll.filter(item => {
            return code?.find((menuItem) => menuItem === item['DepartmentCode'])
        })
        setOptionDepartment(List)
    }

    const findRoom = () => {
        let ListRoom = []
        for (let i = 0; i < optionDepartment.length; i++) {
            if (optionDepartment[i]['DepartmentCode'] === department) {
                ListRoom.push(optionDepartment[i]['RoomConference'])
            }
        }
        setOptionRoom(ListRoom)
    }

    const clickConference = () => {
        const url = []
        for (let i = 0; i < optionDepartment.length; i++) {
            if (optionDepartment[i]['DepartmentCode'] === department) {
                url.push(optionDepartment[i]['RoomConference'][0]['ConferenceURL'])
            }
        }
        console.log(url[0])
        window.open(url[0], '_system')
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            <Box sx={{
                fontSize: 18,
                '@media (max-width: 1023px)': {
                    fontSize: 14,
                },
                '@media (max-width: 800px)': {
                    fontSize: 12,
                }
            }}>
                {/* <Box sx={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #E1E3E9',
                    borderRadius: 2,
                    marginTop: 2,
                    padding: 2,
                    '@media (max-width: 1023px)': {
                        marginTop: 2,
                        padding: 1
                    },
                    '@media (max-width: 800px)': {
                        marginTop: 1,
                        padding: 1
                    }
                }}>
                    <div style={{ color: '#1F2BA6' }} >Filters</div>
                    <Box sx={{
                        padding: '10px 0',
                        display: 'grid',
                        gridTemplateColumns: '2fr 2fr 2fr 1fr',
                        gridGap: 30,
                        alignItems: 'flex-end',
                        '@media (max-width: 1100px)': {
                            padding: '5px 0',
                            gridTemplateColumns: '1fr 1fr',
                            gridGap: 20
                        },
                        '@media (max-width: 800px)': {
                            padding: '5px 20px',
                            gridTemplateColumns: '1fr',
                            gridGap: 10
                        }
                    }}>
                        <FormControl>
                            <div style={{ color: '#1F2BA6' }} >โรงพยาบาลที่ขอรับคำปรึกษา</div>
                            <Select size="small"
                                value={hospital || ' '}
                                onChange={(event) => {
                                    const {
                                        target: { value },
                                    } = event;
                                    setHospital(value)
                                }}
                            >
                                <MenuItem value=" ">เลือกโรงพยาบาล</MenuItem>
                                {optionHospital.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        value={item['ClinicCode']}
                                    >
                                        {item['ClinicName']}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <div style={{ color: '#1F2BA6' }} >แผนกที่เข้ารับบริการ</div>
                            <Select size="small"
                                disabled={hospital === '' ? true : null}
                                value={department || ' '}
                                onChange={(event) => {
                                    const {
                                        target: { value },
                                    } = event;
                                    setDepartment(value)
                                }}
                            >
                                <MenuItem value=" ">เลือกแผนก</MenuItem>
                                {optionDepartment.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        value={item['DepartmentCode']}
                                    >
                                        {item['DepartmentName']}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <div style={{ color: '#1F2BA6' }} >เลือกห้อง</div>
                            <Select size="small"
                                disabled={department === '' ? true : null}
                                value={room || ' '}
                                onChange={(event) => {
                                    const {
                                        target: { value },
                                    } = event;
                                    setRoom(value)
                                    console.log(value)
                                }}
                            >
                                <MenuItem value=" ">ห้อง</MenuItem>
                                {optionRoom[0]?.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        value={item['RoomConferenceName']}
                                    >
                                        {item['RoomConferenceName']}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box sx={{
                            '@media (max-width: 800px)': {
                                display: 'flex',
                                justifyContent: 'center'
                            }
                        }}>
                            <Button
                                variant="contained"
                                disabled={room === '' ? true : null}
                                onClick={() => { clickConference() }}
                            >Conference</Button>
                        </Box>

                    </Box>
                </Box> */}

                <div style={{ fontSize: 24, display: 'flex', justifyContent: 'center', margin: '10% 0' }}>ยังไม่มีข้อมูล</div>

            </Box >
        </>
    )
}
