import React, { useEffect } from 'react'
import { backgroundModal, boxModalV2, buttomPrimary } from './ModalAccessCss'
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Grid, IconButton, Typography, Button } from '@mui/material';
import CardThreeContainer from '../CardController/CardThreeContainer';

import icon_people_3_humen from '../../assets/icons/people-3-humen.svg'
import icon_book_medical_list from '../../assets/icons/book-medical-list.svg'
import icon_record_history from '../../assets/icons/record-history.svg'
import icon_see_doctor from '../../assets/icons/see-doctor.svg'
import icon_export_painat from '../../assets/icons/export-painat.svg'
import icon_pharmacist from '../../assets/icons/pharmacist.svg'
import { useState } from 'react';


function ModalConfinueAssignment({
    onClose,
    dataState,
    form,
    submitForm,
}) {

    // console.log(form)
    const [list_assignment, setListAssignment] = useState([])

    let data_img = [
        {
            'img': icon_people_3_humen,
            'active': false,
            'StateCode': 'SCREEN_APPOINT',
        },
        {
            'img': icon_book_medical_list,
            'active': false,
            'StateCode': 'MEDICAL_RECORD',
        },
        {
            'img': icon_record_history,
            'active': false,
            'StateCode': 'TAKE_HISTORY',
        },
        {
            'img': icon_see_doctor,
            'active': false,
            'StateCode': 'DOCTOR',
        },
        {
            'img': icon_export_painat,
            'active': false,
            'StateCode':'NEXT_APPOINT'
        },
        {
            'img': icon_pharmacist,
            'active': false,
            'StateCode': 'DRUG_ORDER'
        },
    ]

    useEffect(() => {
        listAppointmentState()
    },[])

    useEffect(() => {
        selectState()
    },[list_assignment])


    const  listAppointmentState = () => {
        let new_data = dataState.map(item => {
            const imgData = data_img.find(imgItem => imgItem.StateCode === item.StateCode);
            return {...item, img: imgData ? imgData.img : null};
          });
        // console.log(new_data)
        let set_default = new_data.map((item) => 
            item['StateCode'] === form['appoint_state'] ? { ...item, active: !item.active } : {...item, active: false}
        )
        setListAssignment(set_default)
    }

    const toggleActive = (index) => {
        const setActive = list_assignment.map((item,i) => 
            i === index ? { ...item, active: !item.active } : {...item, active: false}
        )
        setListAssignment(setActive)
    };

    const selectState = () => {
        let state = list_assignment.find((item) => item['active'] === true)
        if (state) {
            form['appoint_state'] = state['StateCode']
        } else {
            return null; 
        }

    }



    return (
        <Box sx={backgroundModal}>
            <Box sx={boxModalV2}>
                <Box sx={{ textAlign: 'end', '&:hover': { cursor: 'pointer' } }}>
                    <IconButton onClick={() => { onClose() }}>
                        <ClearIcon />
                    </IconButton>
                </Box>

                <Typography component="h1" variant="h1" color={'#1F2BA6'}
                    sx={{
                        fontSize: 'calc(18px + 0.10vw)',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginBottom: 'calc(18px + 0.001vw)'
                    }}>ส่งต่อ</Typography>

                <div style={{ height: '450px', overflowY: 'scroll' }}>
                    {
                        list_assignment.map((item, index) => {
                            if(item['StateCode'] === 'NEW_APPOINT'){
                                return 
                            }
                            return <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    border: '1px solid #E1E3E9',
                                    padding: '1rem',
                                    mx: '2rem',
                                    gap: '1rem',
                                    borderRadius: '9px',
                                    backgroundColor: item['active'] ? '#00D0FF17' : 'none',
                                    my: '10px',
                                    // pl: '5rem',
                                    cursor: 'pointer',
                                    '@media (max-width: 800px)': {
                                        // pl: '2rem',
                                        mx: '0.3rem',
                                    },
                                }}

                                onClick={() => toggleActive(index)}
                            >
                                {/* Column 1 */}
                                <Box sx={{margin:'0 1rem'}}>

                                    <img src={item['img']} alt='Icon' />
                                </Box>

                                {/* Column 2 */}
                                <Box>
                                    <Typography sx={{
                                        fontWeight: 'bold'
                                    }}>
                                        {item['StateName']}
                                    </Typography>
                                </Box>
                            </Box>
                        })
                    }
                </div>

                <Grid container justifyContent="center" gap={2} sx={{margin:'1rem 0'}}>
                    <Grid item>
                        <Button variant="outlined"  onClick={() => {
                            onClose()
                        }}>
                            ยกเลิก
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={() => {
                            submitForm()
                        }}>
                            ยืนยัน
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    )
}

export default ModalConfinueAssignment