export const buttonPrimary = {
    backgroundColor: '#1F2BA6',
    border: '1px solid #1F2BA6',
    padding: '0.5rem 1rem',
    width: 'auto',
    borderRadius: 1,
    color: '#fff',
    boxShadow:'none',
    '&:hover': {
      backgroundColor:'#1F2BA6', 
      boxShadow:'none',
    }
  }

export const buttonSecondary = {
    backgroundColor: '#fff',
    border: '1px solid #1F2BA6',
    padding: '0.5rem 1rem',
    width: 'auto',
    borderRadius: 1,
    color: '#1F2BA6',
    boxShadow:'none',
    '&:hover': {
        backgroundColor: '#fff',
        color: '#1F2BA6',
        boxShadow:'none',
    }
}

export const buttonThird = {
    backgroundColor: '#00D0FF17',
    border: '1px solid #1F2BA6',
    padding: '0.5rem 1rem',
    width: 'auto',
    borderRadius:'0.5rem',
    color: '#1F2BA6',
    boxShadow:'none',
    width:'100%',
    '&:hover': {
      backgroundColor: '#00D0FF17',
      color: '#1F2BA6',
      boxShadow:'none'
    },
  }
  
  export const buttonFourth = {
    backgroundColor: '#FBFBFB',
    border: 'none',
    padding: '0.5rem 1rem',
    width: 'auto',
    color: '#747676',
    width:'100%',
    boxShadow:'none',
    '&:hover': {
      backgroundColor: '#FBFBFB',
      color: '#747676',
      boxShadow:'none'
    },
  }

export const buttonActive = {
    backgroundColor: '#00D0FF17',
    border: '1px solid #00D0FF17',
    borderRadius: '6px',
    color: '#1F2BA6',
    width : '100PX',
    padding: '0.35rem 0.2rem',
    '&:hover': {
      backgroundColor: '#00D0FF17',
      color: '#1F2BA6',
    }
  }
export const buttonInActive = {
    backgroundColor: '#FAECEB',
    border: '1px solid #FAECEB',
    borderRadius: '6px',
    color: '#D13C32',
    width : '100PX',
    padding: '0.35rem 0.2rem',
    '&:hover': {
        backgroundColor: '#FAECEB',
        color: '#D13C32',
    }
}
  
export const contentMenu = {
  display: 'flex',
  flexDirection: {xl:'row',lg:'row',md:'row',sm:'row',xs:'column'},
  justifyContent: 'center',
  paddingBottom: {xl:'2rem',lg:'2rem',md:'2rem',sm:'2rem',xs:'1rem'},
  gridGap:'1rem'
}

export const contentBox = {
    backgroundColor:'#fff',
    border: '1px solid #E1E3E9',
    borderRadius: '10px',
    marginTop: '2rem'
}

export const menuTable = {
    display: 'flex',
    justifyContent:'end',
    flexDirection: {xl:'row',lg:'row',md:'row',sm:'row',xs:'column'},
    gridGap: '1rem',
    margin: {xl:'2rem',lg:'2rem',md:'2rem',sm:'2rem',xs:'2rem 1rem'}
}

export const menuTable2 = {
  display: 'flex',
  justifyContent:'space-between',
  flexDirection: {xl:'row',lg:'row',md:'row',sm:'row',xs:'column'},
  gridGap: '1rem',
  margin: {xl:'2rem',lg:'2rem',md:'2rem',sm:'2rem',xs:'2rem 1rem'}
}

export const boxPagination = {
    display: 'flex',
    justifyContent: 'space-between',
    margin:'1rem 0',
    padding: {xl:'0 2rem',lg:'0 2rem',md:'0 2rem',sm:'0 2rem',xs:'0 1rem'}
}

export const boxContent = {
  padding: {xl:'2rem 2rem',lg:'2rem 2rem',md:'2rem 2rem',sm:'1rem 1rem',xs:'1rem 1rem'}
}

export const boxDepaertment = {
  border:'1px solid #E1E3E9',
  borderRadius:'10px',
  padding:'0.5rem 1rem',
}

export const boxSettigDepartment = {
  display:'flex',
  justifyContent: {xl:'start',lg:'start',md:'start',sm:'start',xs:'end'},
  gridGap:'1rem',
  alignItems:'center',
  height:'100%',
  // backgroundColor:'aqua',
  marginLeft:'1rem'
}

export const boxSearch = {
  width:{xl:'50%',lg:'50%',md:'100%',sm:'100%',xs:'100%'},
  margin:'2rem 0',
  display:'flex',
  alignItems:'center'
}

export const contentAddRoom = {
  display:'flex',
  justifyContent:'space-between',
  gridGap:'1rem',
  flexDirection:{xl:'row',lg:'row',md:'column',sm:'column',xs:'column'}
}

export const boxAddRoom = {
  // width:{xl:'400px',lg:'300px',md:'100%',sm:'100%',xs:'100%'}
  // marginBottom:'1rem'
  width:'100%'
  
}

export const contentService = {
  display:'flex',
  justifyContent:{xl:'start',lg:'start',md:'space-between',sm:'none',xs:'none'},
  flexDirection: {xl:'row',lg:'row',md:'row',sm:'column',xs:'column'}
}

export const boxService = {
  width:{xl:'500px',lg:'400px',md:'300px',sm:'100%',xs:'100%'},
  marginBottom:'1rem',
  display:'flex',
  alignItems:'center'
}

export const contentSave = {
  display: 'flex',
  flexDirection: {xl:'row',lg:'row',md:'column',sm:'column',xs:'column'},
  justifyContent:'space-between',
  alignItems:{xl:'end',lg:'end',md:'none',sm:'none',xs:'none'},
  gridGap:'1rem'
}

export const boxSave = {
  width:{xl:'400px',lg:'300px',md:'100%',sm:'100%',xs:'100%'}
}

export const contentMenuMed = {
  display: 'flex',
  justifyContent:'start',
  flexDirection: {xl:'row',lg:'row',md:'row',sm:'row',xs:'column'},
  gridGap: '1rem',
  margin: {xl:'2rem 0',lg:'2rem 0',md:'2rem 0',sm:'2rem 0',xs:'1rem 0'}  
}

export const boxAddMed = {
  display:'flex',
  flexDirection: {xl:'row',lg:'row',md:'row',sm:'row',xs:'column'},
  justifyContent:{xl:'space-between',lg:'space-between',md:'space-between',sm:'space-between',xs:'none'},
  alignItems:'center',
  marginBottom: '2rem'
}