import React, { useContext, useEffect, useState } from "react";
import { Grid, TextField, Button, Box, Typography, Link } from "@mui/material"
import loginIcon from '../assets/icons/logo_login.svg'
import thaiIdIcon from '../assets/icons/icon-thaiid.svg'
import thaiOnces from '../assets/icons/icon_ones.svg'
import bgImg from '../assets/img/bg_login$register.png'
import * as api from '../services/public_api'
import LoaderAnimation from '../components/LoaderAnimation';

import { useNavigate } from "react-router-dom"; //router state
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
// import { MainContext } from "../App"
import moment from 'moment/moment';

//alert notification
import { notifyError, notifyInfo, notifyWarning } from "../components/NotifyToast";

//reduce
import { useDispatch } from 'react-redux'

//reduce auth
import { login as LoginReduce } from '../store/authSlice';

const Login = () => {
  const dispatch = useDispatch()
  // const { userData, setUserData } = useContext(MainContext)
  // const [userExpire, setUserExpire] = useState(JSON.parse(localStorage.getItem('ExpireAuth')))
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [login,setLogin] = useState({
    username: '',
    password: '',
  })

  
  const [showPassword,setShowPassword] = useState(false)

  // useEffect(() => {

  //   if (userExpire === null) {
  //     Handle null case if needed
  //   } else {
  //     const { ExpireInTimestamp } = userExpire;
  //     const currentTimestamp = Math.floor(Date.now() / 1000);
  //     console.log(ExpireInTimestamp)
  //     console.log(checkExpireToken(ExpireInTimestamp))
  //     if (checkExpireToken(ExpireInTimestamp)) {
  //       setUserData(userExpire);
  //       toComponentAuth('/auth/home')
  //     } {

  //     }
  //   }
  // }, [userExpire]);

  // const checkExpireToken = (expire) => {
  //   const currentTimestamp = moment().unix();
  //   return currentTimestamp < expire;
  // };

  async function toComponentAuth(path, state) {
    navigate(path, {
      state: state
    })
  }

  const handleOnChange = (e) => {
    const {name,value} = e.target
    setLogin({...login,[name]:value})
  }

  const loginNormal = async() => {
    if(!login['username']){
      return notifyWarning('กรุณากรอก username')
    }  
    if(!login['password']){
      return notifyWarning('กรุณากรอก password')
    }

    setLoading(true)
    let res = await api.login(login['username'],login['password'])
    setLoading(false)
    if(res){
      // console.log("res ====> ",res)
      if(res['data']['code'] === 200){
          dispatch(LoginReduce(res['data']['data']))
          // setUserData(res['data']['data'])
          localStorage.setItem("AccessToken", res['data']['data']['AccessToken'])
          localStorage.setItem("RefreshToken", res['data']['data']['RefreshToken'])
          // localStorage.setItem("ExpireAuth", JSON.stringify(res['data']['data']))
          toComponentAuth('/auth/home')
      }else{
          notifyInfo(res['data']['message'])
          // console.log()
      }
    }else{
      notifyError('ไม่ตอบสนอง')
    }
  }

  const loginThaiId = () => {
    navigate('/loginThaiId')
  }

  const loginOnesId = () => {
    // notifyInfo('ยังไม่พร้อมใช้งาน')
    navigate('/loginOnesid')
  }

  const detatailThaiID = `
        กรมการปกครองขับเคลื่อนโครงการพัฒนาระบบการพิสูจน์
        และยืนยันตัวตนทางดิจิทัล (DOPA-Digital ID) เพื่อสร้าง
        ต้นแบบและนวัตกรรมใหม่ในการพิสูจน์และยืนยันตัวตน
        ทางดิจิทัลของประเทศรองรับการใช้งานบริการภาครัฐ
        ระบบบริการประชาชนที่ยืนยันตัวตนด้วย ThaID`

  const contentLogin = {
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    width:'100%',
    minHeight:'100vh',
    // height:{xl:'100vh',lg:'100vh',md:'100%',sm:'100%',xs:'100%'},
    backgroundImage: `url(${bgImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height:'100%',
    WebkitBackgroundSize: 'cover',
    MozBackgroundSize: 'cover',
    OBackgroundSize: 'cover',
    backgroundSize: 'cover',
    flexDirection:'column',
  }

  const boxLogin = {
    backgroundColor: '#fff',
    // width:{xl:'60%',lg:'60%',md:'60%',sm:'100%',xs:'100%'},
    width:{xl:'450px',lg:'450px',md:'350px',sm:'350px',xs:'350px'},
    padding:{xl:'2rem',lg:'2rem',md:'2rem',sm:'1rem',xs:'1rem'},
    borderRadius: '10px',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    position:'relative',
    margin:'2rem 0 1rem 0',
    '@media (max-width: 300px)': {
      width:'265px'
    },

  } 
  
  const contentLoginThaiId = {
    backgroundColor: '#00D0FF17',
    padding: '2rem',
    borderRadius: '10px',
  }

  const boxPassword = {
    position:'relative'
  }
  
  const boxIcon = {
      position:'absolute',
      top: '0.5rem',
      right:'1rem',
      color:'#747676',
  }

  return (
    <>
      <LoaderAnimation loading={loading} />
       <Box sx={contentLogin}>
        <Box sx={boxLogin}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box sx={{
                  // height:'450px'
                  
                  '@media (max-width: 300px)': {
                   'input':{
                    fontSize:'12px'
                   }
                  },
                  }}>
                  <img src={loginIcon} alt='' style={{
                    margin:'0 auto',
                    display:'block',
                    paddingBottom:'1rem',
                    width:'calc(200px + 0.5vw)'
                    }}/>
                  <Box sx={{marginBottom:'1rem'}}>
                    <Typography component="p" variant="p" color='primary' sx={{fontWeight:'bold',marginBottom:'0.5rem'}}>อีเมล</Typography>
                    <TextField fullWidth
                      value={login['username']}
                      name={'username'}
                      onChange={handleOnChange}
                      placeholder="กรุณาระบุอีเมลที่ลงทะเบียน"
                      size="small"
                    />
                  </Box>
                  <Box sx={{marginBottom:'1rem'}}>
                    <Typography component="p" variant="p" color='primary' sx={{fontWeight:'bold',marginBottom:'0.5rem'}}>รหัสผ่าน</Typography>
                     <Box sx={boxPassword}>
                        <TextField fullWidth 
                            variant="outlined" 
                            type={showPassword? 'text' : 'password'} 
                            value={login['password']}
                            name={'password'}
                            onChange={handleOnChange}
                            placeholder="กรุณาระบุรหัสผ่านที่ลงทะเบียน" 
                            size="small"
                          />
                        <Box sx={boxIcon} onClick={() => setShowPassword(!showPassword)}>
                            {
                                showPassword ?  (<RemoveRedEyeOutlinedIcon/>) : (<VisibilityOffOutlinedIcon/>) 
                            } 
                        </Box>
                      </Box>
                  </Box>
                  <Link href="/forgetPassword" color='secondary' sx={{display:'flex',justifyContent:'end',marginBottom:'1rem'}}>ลืมรหัสผ่าน</Link>
                </Box>
                <Button
                    onClick={() => loginNormal()}
                    fullWidth
                    variant="contained"
                    sx={{padding:'0.5rem 1rem'}}
                    >
                    {'เข้าสู่ระบบ'}
                </Button>    
                <Typography component='p' onClick={() => navigate('/register',{ state: { register: 'normal' } } )} sx={{display:'flex',justifyContent:'center',marginTop:'1rem',textDecoration:'none',fontWeight:'bold',cursor:'pointer'}}>สมัครใช้งาน</Typography>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Box sx={{height:'450px',display:'flex',alignItems:'center'}}>
                  <Box>
                    <Box sx={contentLoginThaiId}>
                      <Typography component="p" variant="p" color='primary' sx={{fontWeight:'bold',marginBottom:'0.5rem'}}>ระบบการพิสูจน์และยืนยันตัวตนทางดิจิทัล</Typography>
                      <Typography component="p" variant="p" color='secondary' sx={{fontWeight:'bold',marginBottom:'0.5rem',fontSize:'14px'}}>{detatailThaiID}</Typography>
                      <Link href="https://www.bora.dopa.go.th/app-thaid/">รายละเอียดเพิ่มเติม</Link>
                    </Box>
                    <Typography component="p" variant="p" color='primary' sx={{fontWeight:'bold',margin:'2rem 0',textAlign:'center'}}>เลือกบริการ Digital ID</Typography>                    
                    <Button onClick={() => loginThaiId()}
                      fullWidth
                      variant="contained"
                      sx={{padding:'0.5rem 1rem',marginBottom:{xl:'1rem',lg:'1rem',md:'1rem',sm:'2rem',xs:'2rem'}}}
                      >
                      <><img src={thaiIdIcon} alt="" style={{display:'inline-block',marginRight:'0.5rem',width:'25px',height:'25px'}}/>{'เข้าสู่ระบบ ด้วย Thai ID'}</>
                    </Button>
                  </Box>                    
                </Box>
                <Button onClick={() => loginOnesId()}
                  fullWidth
                  variant="contained"
                  sx={{padding:'0.5rem 1rem'}}
                  >
                  <><img src={thaiOnces} alt="" style={{display:'inline-block',marginRight:'0.5rem',width:'25px',height:'25px'}}/>{'เข้าสู่ระบบ ด้วย Ones ID'}</>
                </Button>
              </Grid> */}
            </Grid>
          </Box>
          <Box 
              sx={{
                textAlign:'center',
                fontSize:'calc(10px + 0.15vw)',
                padding:'2rem 0 1rem 0',
              }}
            >
                <Typography
                  component="p"
                  variant="p"
                  color="secondary"
                >
                  version 1.0.0 @2023
                </Typography>
                <Typography
                  component="p"
                  variant="p"
                  color="secondary"
                >
                  Empowered by หมอรู้จักคุณ
                </Typography>
            </Box>
       </Box>
    </>
  )

}

export default Login

