import React from 'react'
import qrCode from '../assets/img/qr_code.png'
import { Box,Typography,Button } from '@mui/material';
import {boxModal} from '../components/ModalController/ModalAccessCss'
import ClearIcon from '@mui/icons-material/Clear';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import bgImg from '../assets/img/bg_login$register.png'

const contentBox = {
  width:'100%',
  height:'100vh',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  backgroundImage: `url(${bgImg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover'
}

const buttonPrimary = {
  backgroundColor: '#1F2BA6',
  border: '1px solid #1F2BA6',
  padding: '0.5rem 1rem',
  width: 'auto',
  borderRadius: '8px',
  color: '#fff',
  boxShadow:'none',
  '&:hover': {
    backgroundColor:'#1F2BA6', 
    boxShadow:'none',
  }
}

function LoginThaiID() {

    const navigate = useNavigate()
    const [openModal,setOpenModal] = useState(false)

    async function toComponentAuth(path, state) {
      navigate(path, {
        state: state
      })
    }

    useEffect(() => {
      setOpenModal(true)
    },[])
    
    const conditon_thaiID = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`

    const submitForm = () => {
      // localStorage.setItem("AccessToken", 'sfdsgdfhtfgjfj')
      // toComponentAuth('/auth/home')
    }

  return (
    <>
      {openModal? (
        <ModalCondition text={conditon_thaiID} closeModal={() => setOpenModal(false)}/>
      ) : (
        <Box sx={contentBox} onClick={() => submitForm()}>
            <img src={qrCode} alt=''/>
        </Box>    
      )}
    </>
  )
}

export default LoginThaiID

const ModalCondition = (props) => {
  const { text,closeModal } = props
  
  return (
    
    <Box sx={contentBox}>
      <Box sx={boxModal}>
          <Box sx={{textAlign:'end','&:hover': {cursor:'pointer'}}} onClick={closeModal}><ClearIcon/></Box>
          <Typography component="p" variant="p" color={'#747676'} sx={{fontWeight:'bold',marginBottom:'0.5rem'}}>{text}</Typography>
          <Box sx={{display:'flex',justifyContent:'center',gridGap:'1rem',marginTop:'1.5rem'}}>
            <Button sx={buttonPrimary} onClick={closeModal}>
              ตกลง
            </Button>
          </Box>   
      </Box>    
    </Box>
  )
}