import { AppBar, Avatar, Box, IconButton, InputLabel, Menu, MenuItem, Toolbar, Typography } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import NotificationsOutlined from "@mui/icons-material/NotificationsOutlined"
import { Logout, Money, Settings, Person } from "@mui/icons-material"
import { useProSidebar } from "react-pro-sidebar"
import { useState } from "react"
import * as api from '../services/public_api'
import { useNavigate } from "react-router-dom"

//reduce
import { useDispatch } from 'react-redux';

//reduce auth
import { logout } from '../store/authSlice';

const AppHeader = () => {

    const dispatch = useDispatch();

    // useProSidebar hook to control the sidebar
    const { collapseSidebar, toggleSidebar, broken } = useProSidebar()

    const [anchorEl, setAnchorEl] = useState(null);
    const [changeLocales, setChangeLocales] = useState(null)
    const navigate = useNavigate()

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async() => {
        let res = await api.logout()
        if(res){
            // console.log("res ====>",res)
            if(res['data']['code'] === 200){
                dispatch(logout())
                // navigate('/')
                localStorage.clear()
            }else{
                return alert(res['data']['message'])
            }
        }else{
            return alert('ระบบไม่ตอบสนอง')
        }
        // Perform logout action
        // Example: clear authentication token, redirect to login page, etc.
    };

    return (
        <AppBar position="sticky" sx={styles.appBar}>
            <Toolbar>
                <IconButton onClick={() => broken ? toggleSidebar() : collapseSidebar()}
                    color="primary"
                >
                    <MenuIcon />
                </IconButton>
                <div style={{
                    marginLeft: '1rem',
                    color : '#1F2BA6',
                    fontSize : 'calc(15px + 0.5vw)',
                    fontWeight : '700'
                }}>
                    {/* สํานักงานปลัดกระทรวงกลาโหม */}
                </div>
                {/* <Box
                    component={'img'}
                    sx={styles.appLogo}
                    src="/logo.svg" /> */}
                <Box sx={{ flexGrow: 1 }} />
                {/* <IconButton title="Notifications" color="secondary">
                    <NotificationsOutlined />
                </IconButton> */}

                <IconButton
                    size="large"
                    aria-controls="menu"
                    aria-haspopup="true"

                    onClick={handleMenuOpen}
                >
                    <Avatar src="/assets/samit.jpg" alt="Avatar" sx={{ width: 28, height: 28 }} />
                </IconButton>
                
                <Menu
                    id="menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    sx={{
                        marginTop:'1.5rem',
                    }}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={
                        () => {
                            handleMenuClose()
                            navigate('/profile')
                        }
                    } sx={{ px: '30px'}}>
                        <Person color="primary" sx={{ fontSize: '16px', mr: '20px' }} /> {<Typography>{'โปรไฟล์' }</Typography>}
                    </MenuItem>
                    {/* <MenuItem onClick={handleMenuClose} sx={{ px: '30px' }}>
                        <Settings color="primary" sx={{ fontSize: '16px', mr: '20px' }} /> {'ตั้งค่าบัญชี'}
                    </MenuItem> */}
                    <MenuItem onClick={handleLogout} sx={{ px: '30px' }}>
                        <Logout color="primary" sx={{ fontSize: '16px', mr: '20px' }} /> {'ออกจากระบบ'}
                    </MenuItem>
                </Menu>

            </Toolbar>
        </AppBar>
    )
}

const styles = {
    appBar: {
        bgcolor: '#FBFBFB',
        zIndex : 800
    },
    appLogo: {
        borderRadius: 2,
        width: 40,
        marginLeft: 2,
        cursor: 'pointer'
    }
}

export default AppHeader