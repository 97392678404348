import React from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import {backgroundModal,boxModal,buttomPrimary} from './ModalAccessCss'
import { Box, Button, TextField,Typography,Grid } from "@mui/material";

function ModalAddMedicineGroup(props) {
    const {closeModal,onChange,name,value,submitForm} = props

  return (
    <Box sx={backgroundModal}>
        <Box sx={boxModal}>
            <Box sx={{textAlign:'end','&:hover': {cursor:'pointer'}}} onClick={closeModal}><ClearIcon/></Box>
            <Typography component="h1" variant="h1" color={'#1F2BA6'} sx={{fontSize:'18px',fontWeight:'bold'}}>เพิ่มหมวดยา</Typography> 
            <hr style={{margin:'1.5rem 0',width:'100%'}}/>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <Typography component="p" variant="p" color={'#1F2BA6'} sx={{fontWeight:'bold'}}>ชื่อหมวดยา</Typography> 
                </Grid>
                <Grid item xs={9}>
                    <TextField fullWidth placeholder="ชื่อหมวดยา" variant="outlined" 
                    name={name}
                    value={value}
                    onChange={onChange}
                    />
                </Grid>
            </Grid>
            <Box sx={{display:'flex',justifyContent:'center',gridGap:'1rem',marginTop:'2rem'}}>         
                <Button 
                onClick={submitForm}
                    fullWidth
                    variant="contained"
                    sx={buttomPrimary}
                    >
                    {'บันทึก'}
                </Button>            
            </Box>
        </Box>
    </Box>
  )
}

export default ModalAddMedicineGroup